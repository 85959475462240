import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
// import style manually
import 'react-markdown-editor-lite/lib/index.css'
import { SignalsAPI } from 'shared/api'
import SignalEditor from 'features/SignalEditor/SignalEditor'
import SignalView from './SignalView'
import ToolbarTopPanel from 'shared/ui/Panels/ToolbarTopPanel'
import ButtonWithEditIcon from 'shared/ui/Buttons/ButtonWithEditIcon'
import { Loader } from 'shared/ui/Loader/Loader'
import ButtonWithParseIcon from 'shared/ui/Buttons/ButtonWithParseIcon'

const mdParser = new MarkdownIt()

const SignalItem = (props) => {
    const auth = useContext(AuthContext)
    const isAuthenticated = !!auth.token

    const [updating, setUpdating] = useState(true)
    const [signal, setSignal] = useState(null)

    useEffect(() => {
        const load = async () => {
            try {
                const signal = await SignalsAPI.getByHash(props.match.params.id)
                setSignal(signal)
                setBodyText(signal.description)
                setJsonText(JSON.stringify(signal, null, 2))
                setUpdating(false)
            } catch (e) {
                console.error(e)
            }
        }
        if (updating) load()
    }, [props.match.params.id, updating]) // eslint-disable-line react-hooks/exhaustive-deps

    const [taskUpdating, setTaskUpdating] = useState(false);
    const intervalRef = useRef(null)

    useEffect(() => {
        if (taskUpdating){
            intervalRef.current = setInterval(async() => {
                let tasks = await SignalsAPI.getTaksByHash(props.match.params.id)
                    if (tasks.length > 0)             
                        {
                            clearInterval(intervalRef.current)
                            intervalRef.current = null
                            setUpdating(true)
                            setTaskUpdating(false)
                        }
            }, 5000);
        }
    }, [taskUpdating]);

    const [showEditor, setShowEditor] = useState(false)
    const [bodyText, setBodyText] = useState()

    const [showJsonEditor, setShowJsonEditor] = useState(false)
    const [jsonText, setJsonText] = useState()

    function handleEditorChange({ html, text }) {
        setBodyText(text)
        console.log('handleEditorChange', html, text)
    }

    function saveBody() {
        SignalsAPI.updateDescription(signal._id, bodyText).then((data) => {
            setShowEditor(false)
            setUpdating(true)
        })
    }

    function handleJsonChange(e) {
        setJsonText(e.target.value)
    }

    function saveJson() {
        SignalsAPI.updateSignal(signal._id, jsonText).then((data) => {
            window.location.reload()
        })
    }

    const startParseHandler = () => {
        SignalsAPI.startParse(signal.hash).then(async (data) => {
            setTaskUpdating(true)
        })
    }

    const editHandler = () => {
        setShowEditor(!showEditor)
    }

    const saveHandler = (signal) => {
        setSignal(signal)
        setShowEditor(false)
    }

    if (updating) return <Loader />
    return (
        <Container className="position-relative">
            <ToolbarTopPanel>
                {isAuthenticated && (
                    <ButtonWithEditIcon onClick={editHandler} />
                )}
                &nbsp;
                <ButtonWithParseIcon onClick={startParseHandler} />
            </ToolbarTopPanel>

            {!showEditor && <SignalView signal={signal} />}

            {showEditor && (
                <SignalEditor
                    data={signal}
                    onSave={saveHandler}
                    onCancel={editHandler}
                />
            )}

            {/* {showEditor && (
                        <>
                            <p>
                                <Button onClick={saveBody}>
                                    Сохранить изменения
                                </Button>
                            </p>
                            <Row>
                                <Col>
                                    <MdEditor
                                        value={bodyText}
                                        style={{ height: '500px' }}
                                        renderHTML={(text) =>
                                            mdParser.render(text)
                                        }
                                        onChange={handleEditorChange}
                                    />
                                </Col>
                            </Row>
                        </>
                    )} */}
        </Container>
    )
}

export default SignalItem

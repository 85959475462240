import React, { useEffect } from 'react'
import TaskFileListItem from 'entities/TaskFile/ui/TaskFileListItem'
import TaskFileList from 'entities/TaskFileList/ui/TaskFileList'

import { Loader } from 'shared/ui/Loader/Loader'

const TaskFiles = ({task}) => {
    
    console.log(task)
    return (
        <>
            <h5>Файлы результов</h5>
            <TaskFileList>
                {task == null ? (
                    <Loader />
                ) : (
                    task.taskFiles.map((card, index) => (
                        <TaskFileListItem key={index} card={card} />
                    ))
                )}
            </TaskFileList>

            <h5>Лог обработки</h5>
            <pre>{task.taskResult}</pre>
        </>
    )
}

export default TaskFiles

import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import PropertyInput from './PropertyInput'
import styles from './PropertyGrid.module.css'

const PropertyGrid = (props) => {
    const [currentRow, setCurrentRow] = useState(0)

    const changeHandler = (id, key, value) => {
        props.onChange(id, key, value)
    }

    const prevHandler = () => {
        let newPos = currentRow - 1
        if (newPos >= 0) setCurrentRow(newPos)
    }

    const nextHandler = () => {
        let newPos = currentRow + 1
        if (newPos < props.data.length) setCurrentRow(newPos)
    }

    const focusHandler = (id) => {
        setCurrentRow(id)
    }

    const enterHandler = () => {
        // setData([...data, { id: data.length, name: 'newprop', value: '' }])
        // setCurrentRow(data.length)
    }

    return (
        <div className={styles.propertyGrid}>
            {props.data &&
                props.data.map((item, index) => (
                    <PropertyInput
                        key={index}
                        id={index}
                        editable={currentRow === index}
                        name={item.name}
                        value={item.value}
                        option={item.option}
                        onChange={changeHandler}
                        onPrev={prevHandler}
                        onNext={nextHandler}
                        onFocus={focusHandler}
                        onEnter={enterHandler}
                    />
                ))}
        </div>
    )
}

export default observer(PropertyGrid)

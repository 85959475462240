import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import appStore from 'shared/stores/AppStore'

import { Loader } from 'shared/ui/Loader/Loader'
import SignalList from 'entities/SignalList/ui/SignalList'
import SignalListItem from 'entities/Signal/ui/SignalListItem'
import SignalListSearchInput from 'features/SignalListSearchInput/SignalListSearchInput'

const Signals = (props) => {
    const searchTextHandler = (event) => {
        appStore.signal.setSearchText(event.target.value)
        appStore.signal.updateSignals()
    }

    useEffect(() => {
        appStore.signal.setQueryParam(
            props.category,
            props.subcategory,
            undefined
        )
        appStore.signal.updateSignals()
    }, [props.category, props.subcategory])

    return (
        <>
            <SignalListSearchInput
                value={appStore.signal.searchText}
                onChange={searchTextHandler}
            />
            <p>найдено: {appStore.signal.signalCount}</p>

            <SignalList>
                {appStore.signal.loading ? (
                    <Loader />
                ) : (
                    appStore.signal.signals.map((card, index) => (
                        <SignalListItem key={index} {...card} />
                    ))
                )}
            </SignalList>
        </>
    )
}

export default observer(Signals)

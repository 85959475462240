import React, { useEffect } from 'react'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Button } from 'react-bootstrap'
import { SignalsAPI } from 'shared/api'

import PropertyGrid from 'shared/ui/PropertyGrid/PropertyGrid'

const SignalEditor = ({ data, onSave, onCancel }) => {
    const signal = useLocalObservable(() => ({
        _id: undefined,
        props: [],
        setProperty(id, name, value) {
            this.props[id].name = name
            this.props[id].value = value
        },

        getValue(name) {
            return this.props.find((item) => item.name === name).value
        },
        get category() {
            return this.getValue('category')
        },
        get subcategory() {
            return this.getValue('subcategory')
        },
        get title() {
            return this.getValue('title')
        },

        get toJson() {
            let res = {}

            res.category = this.getValue('category')
            res.subcategory = this.getValue('subcategory')
            res.title = this.getValue('title')
            res.author = this.getValue('author')
            res.summary = this.getValue('summary')
            res.description = this.getValue('description')

            res.props = this.props
                .filter((item) => item.option && item.option.property === true)
                .map((prop) => {
                    return { name: prop.name, value: prop.value }
                })
            return res
        },

        setData(data) {
            console.log('data', data)
            let res = []
            res.push({ name: 'category', value: data.category })
            res.push({ name: 'subcategory', value: data.subcategory })
            res.push({ name: 'title', value: data.title })
            res.push({ name: 'author', value: data.author })
            res.push({ name: 'summary', value: data.summary })
            res.push({ name: 'description', value: data.description })

            const p = data.props.map((item) => {
                return {
                    name: item.name,
                    value: item.value,
                    option: { property: true },
                }
            })

            this.props = [...res, ...p]
            this._id = data._id
        },

        save() {
            if (this._id != undefined) {
                SignalsAPI.updateSignal(this._id, this.toJson).then((data) => {
                    runInAction(() => {
                        onSave(data)
                    })
                })
            } else {
                SignalsAPI.create(this.toJson).then((data) => {
                    runInAction(() => {
                        onSave(data)
                    })
                })
            }
        },
    }))

    useEffect(() => {
        signal.setData(data)
    }, [data])

    return (
        <>
            <PropertyGrid data={signal.props} onChange={signal.setProperty} />
            {onSave && (
                <Button onClick={signal.save} variant="success">
                    Сохранить
                </Button>
            )}{' '}
            {onCancel && (
                <Button onClick={onCancel} variant="light">
                    Отмена
                </Button>
            )}
        </>
    )
}

export default observer(SignalEditor)

import { makeAutoObservable, runInAction } from 'mobx'
import { SignalsAPI } from 'shared/api'

class SignalListStore {
    loading = false
    signals = []

    category = undefined
    subcategory = undefined
    searchText = undefined

    constructor(appStore) {
        makeAutoObservable(this)
    }

    setQueryParam(category, subcategory, searchText) {
        this.category = category
        this.subcategory = subcategory
        this.searchText = searchText
    }

    setSearchText(searchText) {
        this.searchText = searchText
    }

    updateSignals() {
        this.loadSignals(this.category, this.subcategory, this.searchText)
    }

    loadSignals(category, subcategory, searchText) {
        this.loading = true

        this.setQueryParam(category, subcategory, searchText)

        SignalsAPI.get({
            searchText: searchText,
            category: category,
            subcategory: subcategory,
        }).then((data) => {
            runInAction(() => {
                this.signals = data
                this.loading = false
            })
        })
    }

    get signalCount() {
        return this.signals?.length
    }

    removeSignal(signalId) {
        SignalsAPI.remove(signalId).then((data) => {
            this.updateSignals()
        })
    }
}

export default SignalListStore

import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from 'context/AuthContext'
import styles from './Article.module.css'
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
// import style manually
import 'react-markdown-editor-lite/lib/index.css'
import Description from '../SignalItem/Description'
import ArticleList from './ArticleList'
import { SignalsAPI } from 'shared/api'

const mdParser = new MarkdownIt()

const Article = (props) => {
    const auth = useContext(AuthContext)
    const isAuthenticated = !!auth.token

    const [updating, setUpdating] = useState(true)
    const [signal, setSignal] = useState(null)

    useEffect(() => {
        SignalsAPI.getByHash(props.match.params.id).then((data) => {
            setSignal(data.signal)
            setBodyText(data.signal.description)
            setUpdating(false)
        })
    }, [updating, props.match.params.id])

    const [showEditor, setShowEditor] = useState(false)
    const [bodyText, setBodyText] = useState()

    function handleEditorChange({ html, text }) {
        setBodyText(text)
        console.log('handleEditorChange', html, text)
    }

    function saveBody() {
        SignalsAPI.updateDescription(signal._id, bodyText).then((data) => {
            setShowEditor(false)
            setUpdating(true)
        })
    }

    return (
        <div>
            {updating ? (
                <Container>
                    <Row>
                        <Col>
                            <h1>...</h1>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <h1>{signal.title}</h1>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="4">
                            <ArticleList
                                onTerminClick={(t) => console.log(t)}
                            />

                            <div className={styles.Summary}>
                                {signal.summary !== undefined && (
                                    <div className={styles.SummaryItem}>
                                        <h5>Summary</h5>
                                        <p>{signal.summary}</p>
                                    </div>
                                )}

                                {signal.props.find(
                                    (e) => e.name === 'urlFile'
                                ) !== undefined && (
                                    <div className={styles.SummaryItem}>
                                        <h5>Files</h5>
                                        <a
                                            className2="btn btn-light"
                                            href={
                                                signal.props.find(
                                                    (e) => e.name === 'urlFile'
                                                ).value
                                            }
                                        >
                                            Download sample
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col lg="8" className={styles.SignalItem}>
                            <Tabs defaultActiveKey="home">
                                <Tab eventKey="home" title="Description">
                                    {isAuthenticated && (
                                        <Button
                                            onClick={() =>
                                                setShowEditor(!showEditor)
                                            }
                                            style={{ float: 'right' }}
                                            className="btn btn-light"
                                        >
                                            Edit
                                        </Button>
                                    )}

                                    <Description text={bodyText} />
                                </Tab>
                                <Tab eventKey="properties" title="Properties">
                                    <dl className="row">
                                        <dt className="col-sm-3">GUID</dt>
                                        <dd className="col-sm-9">
                                            {signal._id}
                                        </dd>
                                        {signal.props.map((param, index) => (
                                            <>
                                                <dt
                                                    key={index}
                                                    className="col-sm-3"
                                                >
                                                    {param.name}
                                                </dt>
                                                <dd className="col-sm-9">
                                                    {param.value}
                                                </dd>
                                            </>
                                        ))}
                                    </dl>
                                </Tab>
                                <Tab eventKey="images" title="Images">
                                    {signal.props.find(
                                        (e) => e.name === 'urlPicture'
                                    ) !== undefined && (
                                        <img
                                            alt="signal"
                                            src={
                                                signal.props.find(
                                                    (e) =>
                                                        e.name === 'urlPicture'
                                                ).value
                                            }
                                        />
                                    )}
                                </Tab>
                                <Tab eventKey="json" title="JSON">
                                    <pre
                                        style={{
                                            width: '100%',
                                            fontSize: '8pt',
                                            border: '1px solid gray',
                                            padding: '10px',
                                            textColor: 'lightGray',
                                        }}
                                    >
                                        {JSON.stringify(signal, null, 2)}{' '}
                                    </pre>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    {showEditor && (
                        <>
                            {'  '}
                            <Button onClick={saveBody}>Save</Button>
                            <Row>
                                <Col>
                                    <MdEditor
                                        value={bodyText}
                                        style={{ height: '500px' }}
                                        renderHTML={(text) =>
                                            mdParser.render(text)
                                        }
                                        onChange={handleEditorChange}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            )}
        </div>
    )
}

export default Article

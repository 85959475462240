import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import PcmChart from './PcmChart'
import { transform } from 'shared/lib/fft/fft'

const SignalChart = ({ format, src, showFFT }) => {
    const [updating, setUpdating] = useState(true)
    const [rowData, setRowData] = useState(null)
    const [data, setData] = useState(null)
    const [dataFFT, setDataFFT] = useState(null)

    useEffect(() => {
        function load() {
            var url = src

            var oReq = new XMLHttpRequest()
            oReq.open('GET', url, true)
            oReq.responseType = 'arraybuffer'

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response // Note: not oReq.responseText
                if (arrayBuffer) {
                    var byteArray = []

                    if (format === 'word')
                        byteArray = new Uint16Array(arrayBuffer)
                    else if (format === 'short')
                        byteArray = new Int16Array(arrayBuffer)
                    else if (format === 'byte')
                        byteArray = new Uint8Array(arrayBuffer)
                    else byteArray = new Int16Array(arrayBuffer)

                    byteArray = byteArray.slice(
                        0,
                        Math.min(byteArray.length, 1000)
                    )

                    setRowData(byteArray)
                    setData(toChartData(byteArray))

                    setUpdating(false)
                }
            }

            oReq.send()
        }
        setUpdating(true)
        load()
    }, [src, format]) // eslint-disable-line react-hooks/exhaustive-deps

    function onSliderChange(left, width) {
        console.log(left, width)
        if (rowData === null) return

        var start = Math.floor(rowData.length * left)

        if (start + 4096 > rowData.length) start = rowData.length - 4096
        var fft = calcFFT(rowData.slice(start, start + 4096))
        setDataFFT(toChartData(fft))
    }

    function toChartData(data) {
        const meteoData = {
            columns: [['x'], ['y0']],
            types: {
                y0: 'line',
                x: 'x',
            },
            names: {
                y0: 'ГАЛС-ВЭ',
            },
            colors: {
                // y0: '#619636',
                y0: '#39C78D',
                y1: 'seagreen',
            },
        }

        var d = new Date('2020-12-24T16:16:06')
        for (var j = 0; j < data.length; j++) {
            meteoData.columns[0].push(j)
            meteoData.columns[1].push(data[j])
        }
        return meteoData
    }

    function calcFFT(byteArray) {
        var real = []
        var imag = []
        var i
        for (i = 0; i < byteArray.length; i++) {
            if (i % 2 === 0) real.push(byteArray[i])
            else imag.push(byteArray[i])
        }

        transform(real, imag)

        const n = byteArray.length / 2
        var res = new Array(n)
        for (i = 0; i < n; i++) {
            let k = i < n / 2 ? i + n / 2 : i - n / 2
            res[k] =
                10 * Math.log(Math.sqrt(real[i] * real[i] + imag[i] * imag[i]))
        }
        return res
    }

    return (
        <>
            {updating ? (
                <Container>
                    <Row style={{ height: '467px', margin: '1rem auto' }}>
                        <Spinner animation="grow" />
                    </Row>
                </Container>
            ) : (
                <>
                    <Row>
                        <Col>
                            <PcmChart
                                data={data}
                                sliderWidth="0.3"
                                onSliderChange={onSliderChange}
                            />
                        </Col>
                        <Col>
                            <PcmChart data={dataFFT} sliderWidth="1" />
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default SignalChart

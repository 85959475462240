import React from "react";
import TaskFileList from "./TaskFileList";
import TaskFileListItem from "entities/TaskFile/ui/TaskFileListItem";

const FileList = ({files, onSelect}) => {
    return (
        <TaskFileList>
                {files.map((file, index) => (
                    <TaskFileListItem key={index} card={file} onClick={ (file) => onSelect(file)} />
                ))}            
        </TaskFileList>
    )

}

export default FileList
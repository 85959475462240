// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilePreview_pre__32jdW {\n    /* font-size: 100%; */\n    height: 300px;\n    padding: 1rem;\n    border: 1px solid gray;\n    background-color: #2a2a2a;\n    color: gray;\n}", "",{"version":3,"sources":["webpack://./src/entities/TaskFileList/ui/FilePreview.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".pre {\n    /* font-size: 100%; */\n    height: 300px;\n    padding: 1rem;\n    border: 1px solid gray;\n    background-color: #2a2a2a;\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pre": "FilePreview_pre__32jdW"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryTab_tabs__IqNZ6 {\n    margin-top: 25px;\n}\n\n.CategoryTab_tab__L8D2x {\n    background-color: rgb(217, 230, 217);\n    padding: 25px 35px 15px 35px;\n    width: 100%;\n    height: 110px;\n    color: #5c596d;\n    align-items: baseline;\n\n    clip-path: polygon(\n        91% 0px,\n        100% 28%,\n        100% 100%,\n        100% 100%,\n        0px 100%,\n        0px 0px\n    );\n}\n\n.CategoryTab_tab__L8D2x:hover {\n    cursor: hand;\n    border-bottom: 8px solid green;\n}\n\n.CategoryTab_total__2HoCx {\n    float: right;\n    font-size: 36pt;\n    font-weight: bolder;\n}\n\n.CategoryTab_active__y8JOP {\n    background-color: seagreen;\n    color: white;\n    margin-top: -10px;\n    height: 120px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/Dashboard/CategoryTab.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,4BAA4B;IAC5B,WAAW;IACX,aAAa;IACb,cAAc;IACd,qBAAqB;;IAErB;;;;;;;KAOC;AACL;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".tabs {\n    margin-top: 25px;\n}\n\n.tab {\n    background-color: rgb(217, 230, 217);\n    padding: 25px 35px 15px 35px;\n    width: 100%;\n    height: 110px;\n    color: #5c596d;\n    align-items: baseline;\n\n    clip-path: polygon(\n        91% 0px,\n        100% 28%,\n        100% 100%,\n        100% 100%,\n        0px 100%,\n        0px 0px\n    );\n}\n\n.tab:hover {\n    cursor: hand;\n    border-bottom: 8px solid green;\n}\n\n.total {\n    float: right;\n    font-size: 36pt;\n    font-weight: bolder;\n}\n\n.active {\n    background-color: seagreen;\n    color: white;\n    margin-top: -10px;\n    height: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "CategoryTab_tabs__IqNZ6",
	"tab": "CategoryTab_tab__L8D2x",
	"total": "CategoryTab_total__2HoCx",
	"active": "CategoryTab_active__y8JOP"
};
export default ___CSS_LOADER_EXPORT___;

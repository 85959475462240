// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyGrid_propertyGrid__QvWGo {\r\n    padding: 1rem 0 2rem 0;\r\n}", "",{"version":3,"sources":["webpack://./src/shared/ui/PropertyGrid/PropertyGrid.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B","sourcesContent":[".propertyGrid {\r\n    padding: 1rem 0 2rem 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyGrid": "PropertyGrid_propertyGrid__QvWGo"
};
export default ___CSS_LOADER_EXPORT___;

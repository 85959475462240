import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Line } from 'rc-progress'
import { Link } from 'react-router-dom'
import { useTermins } from 'context/TerminContext'
import ReactTooltip from 'react-tooltip'

const SingleFilter = (props) => {
    const { termins } = useTermins()

    const truncate = (str, n) => {
        return str.length > n ? str.substr(0, n - 1) + '...' : str
    }

    const calc = (n) => {
        var total = 0
        for (var i = 0, _len = props.filter.values.length; i < _len; i++) {
            if (props.filter.values[i].count > total)
                total = props.filter.values[i].count
        }
        return (n * 100) / total
    }

    return (
        props.filter !== undefined && (
            <>
                <h6
                    data-tip={termins.tooltip(props.filter._id)}
                    style={{ textTransform: 'uppercase2' }}
                >
                    {props.filter._id}
                </h6>

                {props.filter.values.map((filter, index) => (
                    <Link
                        key={index}
                        to="/datasets"
                        data-tip={termins.tooltip(
                            props.filter._id,
                            filter.name
                        )}
                    >
                        <Row>
                            <Col>
                                <span>{truncate(filter.name, 15)}</span>
                            </Col>

                            <Col>
                                <Line
                                    percent={calc(filter.count)}
                                    strokeWidth="2"
                                />
                            </Col>
                        </Row>
                    </Link>
                ))}

                <ReactTooltip delayShow="500" />
            </>
        )
    )
}

export default SingleFilter

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PiePanel_piePanel__JL7XY {\n    margin: '0px auto';\n    max-width: '200px';\n    padding-top: '50px';\n\n    text-align: center;\n    position: relative;\n}\n\n.PiePanel_pieTitle__PNGJd {\n    color: green;\n    text-align: center;\n    font-size: 1.2vw;\n    text-transform: uppercase;\n    margin-top: 12pt;\n    border-top: 2px solid green;\n}\n\n.PiePanel_chartInnerLabel__M8Duz {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    text-align: center;\n    font-size: 1.4vw;\n    color: #5c596d;\n    transform: translate(-50%, -50%);\n}\n\n.PiePanel_chartInnerValue__5-Ao8 {\n    font-size: 3vw;\n    font-stretch: extra-condensed;\n    color: #e38627;\n}\n", "",{"version":3,"sources":["webpack://./src/features/PiePanel/PiePanel.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;;IAEnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,cAAc;AAClB","sourcesContent":[".piePanel {\n    margin: '0px auto';\n    max-width: '200px';\n    padding-top: '50px';\n\n    text-align: center;\n    position: relative;\n}\n\n.pieTitle {\n    color: green;\n    text-align: center;\n    font-size: 1.2vw;\n    text-transform: uppercase;\n    margin-top: 12pt;\n    border-top: 2px solid green;\n}\n\n.chartInnerLabel {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    text-align: center;\n    font-size: 1.4vw;\n    color: #5c596d;\n    transform: translate(-50%, -50%);\n}\n\n.chartInnerValue {\n    font-size: 3vw;\n    font-stretch: extra-condensed;\n    color: #e38627;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"piePanel": "PiePanel_piePanel__JL7XY",
	"pieTitle": "PiePanel_pieTitle__PNGJd",
	"chartInnerLabel": "PiePanel_chartInnerLabel__M8Duz",
	"chartInnerValue": "PiePanel_chartInnerValue__5-Ao8"
};
export default ___CSS_LOADER_EXPORT___;

import { InputGroup, Form } from 'react-bootstrap'
import styles from './SignalListSearchInput.module.css'
import appStore from 'shared/stores/AppStore'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

const SignalListSearchInput = (props) => {
    const textInput = useRef(null)

    if (textInput && textInput.current && props.value === undefined)
        textInput.current.value = ''

    return (
        <div className={styles.cardUl}>
            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder="Поиск по названию ..."
                    style={{
                        borderBottom: '1px solid #cbe0cf',
                        borderWidth: '0px 0px 1px 0',
                        borderRadius: 0,
                    }}
                    ref={textInput}
                    onChange={props.onChange}
                />
            </InputGroup>
        </div>
    )
}

export default observer(SignalListSearchInput)

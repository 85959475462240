import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'

import SpectrogramView from './SpectrogramView'

const SignalSpectrogram = ({ format, src }) => {
    const [updating, setUpdating] = useState(true)
    const [data, setData] = useState(null)
    const [period, setPeriod] = useState(4096)
    const [additive, setAdditive] = useState(10)
    const [multiplicative, setMultiplicative] = useState(2)

    useEffect(() => {
        function load() {
            var url = src

            var oReq = new XMLHttpRequest()
            oReq.open('GET', url, true)
            oReq.responseType = 'arraybuffer'

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response // Note: not oReq.responseText
                if (arrayBuffer) {
                    var byteArray

                    if (format === 'word')
                        byteArray = new Uint16Array(arrayBuffer)
                    else if (format === 'short')
                        byteArray = new Int16Array(arrayBuffer)
                    else if (format === 'byte')
                        byteArray = new Uint8Array(arrayBuffer)
                    else byteArray = new Int16Array(arrayBuffer)

                    setData(byteArray)
                    setUpdating(false)
                }
            }

            oReq.send()
        }
        setUpdating(true)
        load()
    }, [src, format]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {updating ? (
                <Container>
                    <Row style={{ height: '467px', margin: '1rem auto' }}>
                        <Spinner animation="grow" />
                    </Row>
                </Container>
            ) : (
                <>
                    <Row>
                        <Col>
                            <SpectrogramView
                                data={data}
                                options={{ period, additive, multiplicative }}
                            />
                            <Col xs="2">
                                <RangeSlider
                                    variant="dark"
                                    value={additive}
                                    onChange={(e) =>
                                        setAdditive(e.target.value)
                                    }
                                    min={1}
                                    max={255}
                                />
                            </Col>
                            <Col xs="2">
                                <RangeSlider
                                    variant="dark"
                                    value={multiplicative}
                                    onChange={(e) =>
                                        setMultiplicative(e.target.value)
                                    }
                                    min={1}
                                    max={4}
                                />
                            </Col>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default SignalSpectrogram

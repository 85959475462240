import { api } from './configs/axios.config'
import { defineCancelApiObject } from './configs/axios.utils'

export const SignalsAPI = {
    get: async function (query, cancel = false) {
        const response = await api.request({
            url: `/api/signals/`,
            method: 'POST',
            data: JSON.stringify(query),
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getFilters: async function (query, cancel = false) {
        const response = await api.request({
            url: `/api/signals/filters`,
            method: 'POST',
            data: JSON.stringify(query),
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getTermins: async function (cancel = false) {
        const response = await api.request({
            url: `/api/signals/termins`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getArticleTitles: async function (cancel = false) {
        const response = await api.request({
            url: `/api/signals/stat/articleTitles`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    create: async function (signalData, cancel = false) {
        const response = await api.request({
            url: `/api/signals/create`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: signalData,
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    updateSignal: async function (id, signalData, cancel = false) {
        const response = await api.request({
            url: `/api/signals/update/signal/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: signalData,
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    updateDescription: async function (id, description, cancel = false) {
        const response = await api.request({
            url: `/api/signals/update/description`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: { id, description },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    remove: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/signals/remove/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getByHash: async function (hash, cancel = false) {
        const response = await api.request({
            url: `/api/signals/hash/${hash}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getTaksByHash: async function (hash, cancel = false) {
        const response = await api.request({
            url: `/api/tasks/hash/${hash}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getFilePreview: async function (file, cancel = false) {
        const response = await api.request({
            url: process.env.REACT_APP_DEPACK_STORAGE_URL + file,
            method: 'GET',
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    getCategory: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/signals/stat/category/${id}`,
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },
    startParse: async function (id, cancel = false) {
        const response = await api.request({
            url: `/api/tasks/startParse/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.get.name].handleRequestCancellation()
                      .signal
                : undefined,
        })

        return response.data
    },

    getPaginated: async function ({ limit, offset }, cancel = false) {
        const response = await api.request({
            url: '/pokemon/',
            method: 'GET',
            params: {
                limit: limit,
                offset: offset,
            },
            signal: cancel
                ? cancelApiObject[
                      this.getPaginated.name
                  ].handleRequestCancellation().signal
                : undefined,
        })

        return response.data.results
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(SignalsAPI)

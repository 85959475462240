import React from 'react'
import { Alert, Form } from 'react-bootstrap'

const SignalFileUpload = ({ filename, onSelectFile }) => {
    return (
        <>
            {filename && (
                <Alert variant="success">
                    Файл {filename} успешно загружен
                </Alert>
            )}
            <Form.Group controlId="filename" className="mb-3">
                <Form.Label>Выберите файл</Form.Label>
                <Form.Control
                    type="file"
                    onChange={(e) => onSelectFile(e.target.files[0])}
                />
            </Form.Group>
        </>
    )
}

export default SignalFileUpload

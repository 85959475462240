import React, { Component } from 'react'
import { Row, Col, Jumbotron, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PiePanel from 'features/PiePanel/PiePanel'
import Background from './bg-freesignals.jpg'

import imgDatasets from './datasets.png'
import imgSignals from './signals.png'
import imgStructure from './structure.png'

import Plx from 'react-plx'

export default class Home extends Component {
    render() {
        const styles = {
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            marginBottom: '0',
        }

        const cardStyles = {
            backgroundColor: 'white',
            display: 'block',
            padding: '20px',
            height: '470px',
            textDecoration: 'none',
            clipPath:
                'polygon(81% 0, 100% 18%, 100% 100%, 18% 100%, 0 81%, 0 0)',
        }

        const phoneData = [
            {
                start: 'self',
                startOffset: 100,
                duration: 300,
                easing: [0.25, 0.1, 0.6, 1.5],
                properties: [
                    {
                        startValue: 90,
                        endValue: 0,
                        property: 'rotate',
                    },
                    {
                        startValue: 0,
                        endValue: 1,
                        property: 'scale',
                    },
                ],
            },
        ]

        const parallaxData = [
            {
                start: 0,
                end: 200,
                properties: [
                    {
                        startValue: 1,
                        endValue: 1.3,
                        property: 'scale',
                    },
                ],
            },
        ]

        return (
            <>
                <Container
                    style={{
                        textAlign: 'center',
                        paddingBottom: '20px',
                        height: '40vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <h1
                        className="display-2"
                        style={{ textAlign: 'center', fontSize: '6.1vw' }}
                    >
                        Datasig
                    </h1>
                    <Plx parallaxData={parallaxData}>
                        <h3
                            style={{
                                color: 'black',
                                backgroundColor: 'white2',
                                padding: '10px',
                            }}
                        >
                            Информационно-аналитическое обеспечение
                            <br /> методов машинного обучения в области
                            инфокоммуникаций
                        </h3>
                    </Plx>
                </Container>
                <div style={styles}>
                    <Container
                        fluid
                        style={{
                            marginBottom: '40px',
                            padding: '10px 0 10px 0',
                            backgroundColor: 'rgba(255,250,250,0.2)',
                            height: '40vh',
                        }}
                    >
                        <Container
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <PiePanel />
                        </Container>
                    </Container>

                    <Container>
                        <Row style={{ paddingTop: '100px' }}>
                            <Col>
                                <Plx parallaxData={phoneData}>
                                    <Link to="/datasets" style={cardStyles}>
                                        <img alt="datasets" src={imgDatasets} />
                                        <h3>Датасеты</h3>
                                        <p>
                                            База данных радиосигналов для
                                            экспертов в области телекоммуникаций
                                            и ЦОС
                                        </p>
                                    </Link>
                                </Plx>
                            </Col>
                            <Col>
                                <Plx parallaxData={phoneData}>
                                    <Link to="/signals" style={cardStyles}>
                                        <img alt="signals" src={imgSignals} />
                                        <h3>Машинное обучение</h3>
                                        <p>
                                            Решение задачи классификации
                                            сигналов с использованием нейронной
                                            сети
                                        </p>
                                    </Link>
                                </Plx>
                            </Col>
                            <Col>
                                <Plx parallaxData={phoneData}>
                                    <Link to="/wiki" style={cardStyles}>
                                        <img
                                            alt="structure"
                                            src={imgStructure}
                                        />
                                        <h3>AI</h3>
                                        <p>
                                            Разработка новых и оптимизации
                                            характеристик существующих схем
                                            приемных устройств
                                        </p>
                                    </Link>
                                </Plx>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

import { tooltip } from './tooltip'
import {
    css,
    isOver,
    circle,
    line,
    boundaries,
    toCoords,
    computeYRatio,
    computeXRatio,
} from './utils'
import { sliderChart } from './slider'

export function chart(root, data, opt) {
    var options = {}

    options.root = root
    options.ROWS_COUNT = 5
    options.PADDING = 40
    options.WIDTH = root.clientWidth
    options.HEIGHT = 400
    options.DPI_WIDTH = options.WIDTH * 2
    options.DPI_HEIGHT = options.HEIGHT * 2
    options.VIEW_HEIGHT = options.DPI_HEIGHT - options.PADDING * 2
    options.VIEW_WIDTH = options.DPI_WIDTH
    options.DEFAULT_WIDTH_PERCENT = 0.3

    options = { ...options, ...opt }

    const canvas = root.querySelector('[data-el="main"]')
    const tip = tooltip(root.querySelector('[data-el="tooltip"]'))
    const slider = sliderChart(root.querySelector('[data-el="slider"]'), data, {
        onSliderChange: options.onSliderChange,
        DPI_WIDTH: options.DPI_WIDTH,
        DEFAULT_WIDTH_PERCENT: options.DEFAULT_WIDTH_PERCENT,
    })
    const ctx = canvas.getContext('2d')
    let raf
    canvas.width = options.DPI_WIDTH
    canvas.height = options.DPI_HEIGHT
    css(canvas, {
        width: options.WIDTH + 'px',
        height: options.HEIGHT + 'px',
    })

    const proxy = new Proxy(
        {},
        {
            set(...args) {
                const result = Reflect.set(...args)
                raf = requestAnimationFrame(paint)
                return result
            },
        }
    )

    if (slider !== undefined)
        slider.subscribe((pos) => {
            proxy.pos = pos
        })

    canvas.addEventListener('mousemove', mousemove)
    canvas.addEventListener('mousedown', mousedown)
    canvas.addEventListener('mouseup', mouseup)
    canvas.addEventListener('mouseleave', mouseleave)
    canvas.onwheel = mousewheel
    window.addEventListener('resize', resize)

    function mousemove({ clientX, clientY }) {
        const { left, top } = canvas.getBoundingClientRect()
        proxy.mouse = {
            x: (clientX - left) * 2,
            tooltip: {
                left: clientX - left,
                top: clientY - top,
            },
        }
    }

    function mousedown(event) {
        const startX = event.pageX
        var prev = slider.getPosition()

        document.onmousemove = (e) => {
            const delta = startX - e.pageX
            if (delta === 0) {
                return
            }

            slider.setPosition(prev[0] + delta, options.WIDTH - delta - prev[1])
        }
    }

    function mousewheel(event) {
        const delta = Math.sign(event.deltaY) * 5
        var prev = slider.getPosition()
        slider.setPosition(prev[0] - delta, options.WIDTH - delta - prev[1])
        return false
    }

    function mouseup() {
        document.onmousemove = null
    }

    function mouseleave() {
        proxy.mouse = null
        tip.hide()
    }

    function resize() {
        options.WIDTH = root.clientWidth
        options.HEIGHT = 400
        options.DPI_WIDTH = options.WIDTH * 2
        options.DPI_HEIGHT = options.HEIGHT * 2
        options.VIEW_HEIGHT = options.DPI_HEIGHT - options.PADDING * 2
        options.VIEW_WIDTH = options.DPI_WIDTH
        options.DEFAULT_WIDTH_PERCENT = 0.3

        canvas.width = options.DPI_WIDTH
        canvas.height = options.DPI_HEIGHT
        css(canvas, {
            width: options.WIDTH + 'px',
            height: options.HEIGHT + 'px',
        })

        slider.resize(options.DPI_WIDTH)
    }

    function clear() {
        ctx.clearRect(0, 0, options.DPI_WIDTH, options.DPI_HEIGHT)
    }

    function paint() {
        clear()

        if (data === undefined) return

        const length = data.columns[0].length
        const leftIndex = Math.round((length * proxy.pos[0]) / options.WIDTH)
        const rightIndex = Math.round((length * proxy.pos[1]) / options.WIDTH)

        const columns = data.columns.map((col) => {
            const res = col.slice(leftIndex, rightIndex)
            if (typeof res[0] !== 'string') {
                res.unshift(col[0])
            }
            return res
        })

        const [yMin, yMax] = boundaries({ columns, types: data.types })

        const yRatio = computeYRatio(options.VIEW_HEIGHT, yMax, yMin)
        const xRatio = computeXRatio(options.VIEW_WIDTH, columns[0].length)

        const yData = columns.filter((col) => data.types[col[0]] === 'line')
        const xData = columns.filter((col) => data.types[col[0]] !== 'line')[0]

        yAxis(yMin, yMax)
        xAxis(xData, yData, xRatio)

        yData
            .map(
                toCoords(
                    xRatio,
                    yRatio,
                    options.DPI_HEIGHT,
                    options.PADDING,
                    yMin
                )
            )
            .forEach((coords, idx) => {
                const color = data.colors[yData[idx][0]]
                line(ctx, coords, { color })

                for (const [x, y] of coords) {
                    if (
                        isOver(proxy.mouse, x, coords.length, options.DPI_WIDTH)
                    ) {
                        circle(ctx, [x, y], color)
                        break
                    }
                }
            })
    }

    function xAxis(xData, yData, xRatio) {
        const colsCount = 6
        const step = Math.round(xData.length / colsCount)
        ctx.beginPath()
        for (let i = 1; i < xData.length; i++) {
            const x = i * xRatio

            if ((i - 1) % step === 0) {
                const text = xData[i]
                ctx.fillText(text.toString(), x, options.DPI_HEIGHT - 10)
            }

            if (isOver(proxy.mouse, x, xData.length, options.DPI_WIDTH)) {
                ctx.save()
                ctx.moveTo(x, options.PADDING / 2)
                ctx.lineTo(x, options.DPI_HEIGHT - options.PADDING)
                ctx.restore()

                tip.show(proxy.mouse.tooltip, {
                    title: xData[i],
                    items: yData.map((col) => ({
                        color: data.colors[col[0]],
                        name: data.names[col[0]],
                        value: col[i + 1],
                    })),
                })
            }
        }
        ctx.stroke()
        ctx.closePath()
    }

    function yAxis(yMin, yMax) {
        const step = options.VIEW_HEIGHT / options.ROWS_COUNT
        const textStep = (yMax - yMin) / options.ROWS_COUNT

        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = '#bbb'
        ctx.font = 'normal 20px Helvetica,sans-serif'
        ctx.fillStyle = '#96a2aa'
        for (let i = 1; i <= options.ROWS_COUNT; i++) {
            const y = step * i
            const text = Math.round(yMax - textStep * i)
            ctx.fillText(text.toString(), 5, y + options.PADDING - 10)
            ctx.moveTo(0, y + options.PADDING)
            ctx.lineTo(options.DPI_WIDTH, y + options.PADDING)
        }
        ctx.stroke()
        ctx.closePath()
    }

    return {
        init() {
            paint()
        },
        destroy() {
            cancelAnimationFrame(raf)
            canvas.removeEventListener('mousemove', mousemove)
            canvas.removeEventListener('mouseleave', mouseleave)
            canvas.removeEventListener('mousedown', mousedown)
            canvas.removeEventListener('mouseup', mouseup)
            canvas.onwheel = null
            window.removeEventListener('resize', resize)
        },
    }
}

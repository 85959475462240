import React, { useContext, Fragment } from 'react'
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
import { AuthContext } from 'context/AuthContext'
import Description from './Description'
import SignalBitView from '../Widgets/SignalBitView'
import SignalSpectrogram from '../Widgets/SignalSpectrogram'
import SignalChart from '../Widgets/SignalChart'
import styles from './SignalItem.module.css'
import TaskFiles from 'features/TaskFiles/TaskFiles'
import FilePanel from 'features/FilePanel/FilePanel'

const SignalView = ({ signal }) => {
    const auth = useContext(AuthContext)
    const isAuthenticated = !!auth.token

    function isSignalProps(name) {
        return signal.props.find((e) => e.name === name) !== undefined
    }
    function getSignalSrcProps(name) {
        let res = signal.props.find((e) => e.name === name)
        if (res === undefined) return null

        if (res.value.startsWith('http')) return res.value
        return process.env.REACT_APP_DATASIG_STORAGE_URL + res.value
    }
    function getSignalProps(name) {
        let res = signal.props.find((e) => e.name === name)
        if (res === undefined) return null

        return res.value
    }

    return (
        <>
            <Row className={styles.Title}>
                <Col>
                    <h1>{signal.title}</h1>
                    <h4>{signal.author}</h4>
                </Col>
            </Row>

            {isSignalProps('widget-pcm-src') && (
                <Row>
                    <Col>
                        <SignalChart
                            src={getSignalSrcProps('widget-pcm-src')}
                            format={getSignalProps('widget-dataformat')}
                        />
                    </Col>
                </Row>
            )}

            {isSignalProps('widget-spectrogram-src') && (
                <Row>
                    <Col>
                        <SignalSpectrogram
                            src={getSignalSrcProps('widget-spectrogram-src')}
                            format={getSignalProps('widget-dataformat')}
                        />
                        <br />
                    </Col>
                </Row>
            )}

            {isSignalProps('widget-bitview-src') && (
                <Row>
                    <Col>
                        <SignalBitView
                            src={getSignalSrcProps('widget-bitview-src')}
                            format={getSignalProps('widget-dataformat')}
                        />
                        <br />
                    </Col>
                </Row>
            )}

            <Row>
                <Col xs lg="4">
                    <div className={styles.Summary}>
                        {signal.summary !== undefined && (
                            <div className={styles.SummaryItem}>
                                <h5>Сводка</h5>
                                <p>{signal.summary}</p>
                            </div>
                        )}

                        {isSignalProps('urlFile') && (
                            <div className={styles.SummaryItem}>
                                <h5>Файлы</h5>
                                <a href={getSignalProps('urlFile')}>
                                    Download sample
                                </a>
                            </div>
                        )}
                    </div>

                    {/* {isAuthenticated && (
                        <>
                            <div>
                                <Button
                                    onClick={() => setShowEditor(!showEditor)}
                                    className="btn btn-light"
                                >
                                    Изменить описание
                                </Button>
                            </div>
                            <div>
                                <Button
                                    href={
                                        '/admin/edit/' + props.match.params.id
                                    }
                                    className="btn btn-light"
                                >
                                    Редактировать
                                </Button>
                            </div>
                        </>
                    )} */}
                </Col>
                <Col lg="8" xs className={styles.SignalItem}>
                    <Tabs defaultActiveKey="home">
                        <Tab eventKey="home" title="Описание">
                            <Description text={signal.description} />
                        </Tab>
                        <Tab eventKey="properties" title="Характеристики">
                            <dl className="row">
                                <dt className="col-sm-3">GUID</dt>
                                <dd className="col-sm-9">{signal._id}</dd>
                                {signal.props.map((param, index) => (
                                    <Fragment key={index}>
                                        <dt className="col-sm-3">
                                            {param.name}
                                        </dt>
                                        <dd className="col-sm-9">
                                            {param.value}
                                        </dd>
                                    </Fragment>
                                ))}
                            </dl>
                        </Tab>
                        <Tab eventKey="images" title="Изображения">
                            {isSignalProps('urlPicture') && (
                                <img
                                    alt="signal"
                                    src={getSignalProps('urlPicture')}
                                />
                            )}
                        </Tab>

                        {/* {isAuthenticated && (
                            <Tab eventKey="json" title="JSON">
                                {!showJsonEditor ? (
                                    <>
                                        <Button
                                            onClick={() =>
                                                setShowJsonEditor(
                                                    !showJsonEditor
                                                )
                                            }
                                            className="btn btn-light"
                                        >
                                            Изменить JSON
                                        </Button>
                                        <pre
                                            style={{
                                                width: '100%',
                                                fontSize: '8pt',
                                                border: '1px solid gray',
                                                padding: '10px',
                                                textColor: 'lightGray',
                                            }}
                                        >
                                            {jsonText}
                                        </pre>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            onClick={saveJson}
                                            className="btn btn-primary"
                                        >
                                            Сохранить JSON
                                        </Button>
                                        <div>
                                            <textarea
                                                defaultValue={jsonText}
                                                style={{
                                                    width: '100%',
                                                }}
                                                rows="25"
                                                onChange={handleJsonChange}
                                            />
                                        </div>
                                    </>
                                )}
                            </Tab>
                        )} */}
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                        <FilePanel id={signal.hash}/>
                </Col>
            </Row>

        </>
    )
}

export default SignalView

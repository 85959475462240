import { api } from './configs/axios.config'

export const AuthAPI = {
    login: async function (loginData) {
        const response = await api.request({
            url: `/api/auth/login`,
            method: 'POST',
            data: loginData,
            headers: {
                'content-type': 'application/json',
            },
        })

        return response.data
    },
}

import React, { useState } from 'react'
import { Row, Col, Container, ListGroup, Button } from 'react-bootstrap'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import styles from './Datasets.module.css'
import FilterPanel from 'features/FilterPanel/FilterPanel'
import Signals from 'features/Signals/Signals'
import AggregateBar from 'features/AggregateBar/AggregateBar'
import ButtonWithPlusIcon from 'shared/ui/Buttons/ButtonWithPlusIcon'
import ButtonWithFilterIcon from 'shared/ui/Buttons/ButtonWithFilterIcon'
import ToolbarTopPanel from 'shared/ui/Panels/ToolbarTopPanel'

const Datasets = ({ category }) => {
    let { subcategory } = useParams()
    const history = useHistory()

    const [showFilter, setShowFilter] = useState(false)

    const toggleFilterHandler = () => {
        setShowFilter(!showFilter)
    }

    const createArticleHandler = () => {
        history.push('/admin/upload')
    }

    return (
        <Container className="position-relative">
            <ToolbarTopPanel>
                <ButtonWithFilterIcon onClick={toggleFilterHandler} />
                <ButtonWithPlusIcon onClick={createArticleHandler} />
            </ToolbarTopPanel>
            <h1 style={{ textTransform: 'capitalize' }}>
                {category}{' '}
                <span>
                    {subcategory === undefined ? '' : '/ ' + subcategory}
                </span>{' '}
            </h1>
            <br />

            <Row>
                <Col lg="3">
                    <AggregateBar category={category} />
                </Col>
                <Col lg={showFilter ? 6 : 9}>
                    <Signals category={category} subcategory={subcategory} />
                </Col>
                <FilterPanel
                    show={showFilter}
                    category={category}
                    subcategory={subcategory}
                />
            </Row>
            <Row></Row>
        </Container>
    )
}

export default Datasets

import React, { useState, useCallback } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import MarkdownIt from 'markdown-it'
import { useTermins } from 'context/TerminContext'
import { translit } from 'shared/lib/translit/Translit'

const mdParser = new MarkdownIt()

const Description = ({ text }) => {
    const { termins, terminsReady } = useTermins()
    const [showTermins, setShowTermins] = useState(false)
    const history = useHistory()

    function highlightTerm(text) {
        var str = text
        var search = termins.titles
        var i, len
        for (i = 0, len = search.length; i < len; i++) {
            str = str.replace(RegExp(search[i], 'g'), '{' + (i + 1) + '}')
        }

        for (i = 0; i < search.length; i++) {
            str = str.replace(
                new RegExp('\\{' + (i + 1) + '\\}', 'g'),
                '[' + search[i] + '](/item/' + translit(search[i]) + ')'
            )
        }

        return str
    }

    const descriptionRef = useCallback((node) => {
        const clickHandler = (e) => {
            var href = e.target.getAttribute('href')
            history.push(href)
            //if (e.target.href !== undefined) alert(e.target.href)
            e.preventDefault()
        }

        if (node != null) node.addEventListener('click', clickHandler)
        return () => {
            if (node != null) node.removeEventListener('click', clickHandler)
        }
    }, [])

    return (
        <>
            {terminsReady && (
                <Button
                    onClick={() => setShowTermins(!showTermins)}
                    style={{ float: 'right', margin: '10px' }}
                    className="btn btn-light"
                >
                    ^
                </Button>
            )}
            <div
                ref={descriptionRef}
                dangerouslySetInnerHTML={{
                    __html: mdParser.render(
                        showTermins ? highlightTerm(text) : text
                    ),
                }}
            />
        </>
    )
}

export default Description

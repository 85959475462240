// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a {\n    color: green;\n    text-decoration: none;\n}\n\na:hover {\n    color: forestgreen;\n    text-decoration: none;\n}\n\n.Datasets_active__wMGyh a {\n    background-color: blueviolet;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Datasets/Datasets.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["a {\n    color: green;\n    text-decoration: none;\n}\n\na:hover {\n    color: forestgreen;\n    text-decoration: none;\n}\n\n.active a {\n    background-color: blueviolet;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": "Datasets_active__wMGyh"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

const ButtonWithFilterIcon = (props) => {
    return (
        <Button onClick={props.onClick} variant="default">
            <FontAwesomeIcon icon={faFilter} className="" />
        </Button>
    )
}

export default ButtonWithFilterIcon

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignalList_cardUl__DLy96 {\n    list-style: none;\n    padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/entities/SignalList/ui/SignalList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd","sourcesContent":[".cardUl {\n    list-style: none;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardUl": "SignalList_cardUl__DLy96"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    Container,
    Form,
    Button,
    Row,
    Col,
    Alert,
    InputGroup,
} from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import SinglePropEditor from './SinglePropEditor'
import {
    getSubCategories,
    getAvailableProperties,
} from 'entities/SignalDataScheme'
import { SignalsAPI } from 'shared/api'

const ArticleEditor = (props) => {
    let { hash } = useParams()
    const isnew = hash === undefined

    const history = useHistory()

    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(null)
    const [signal, setSignal] = useState(null)

    useEffect(() => {
        const load = async () => {
            setUpdating(true)
            try {
                const signal = await SignalsAPI.getByHash(hash)
                setSignal(signal)
                setForm(signal)
                setUpdating(false)
            } catch (e) {
                setError(e.message)
            }
        }
        if (!isnew) load()
    }, [hash]) // eslint-disable-line react-hooks/exhaustive-deps

    const [form, setForm] = useState({
        category: 'article',
        subcategory: 'Termin',
        title: '',
        description: '',
        summary: '',
        props: [],
    })
    const [selectPropName, setSelectPropName] = useState('--')

    const getSubcategoryOptions = (category) => {
        return (getSubCategories(category) || []).map((item, index) => (
            <option key={index}>{item._id}</option>
        ))
    }

    const getAvailablePropertyOptions = (subcategory) => {
        return (getAvailableProperties(subcategory) || []).map(
            (item, index) => <option key={index}>{item}</option>
        )
    }

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value })
    }

    const changePropHandler = (prop) => {
        let props = [...form.props]
        let p = props.find((item) => item._id === prop._id)
        console.log('changePropHangler', p, prop)
        if (p !== undefined) {
            p.value = prop.value
            setForm({
                ...form,
                props: props,
            })
        }
    }

    const changeJsonHandler = (event) => {
        try {
            //let f = JSON.parse(event.target.value)
            //setForm({ ...f })
        } catch (e) {
            console.log(e)
        }
    }

    const addPropHandler = (event) => {
        if (selectPropName === '--') return
        setForm({
            ...form,
            props: form.props.concat({
                id: uuidv4(),
                name: selectPropName,
                value: '',
            }),
        })
    }

    const removePropHandler = (id) => {
        const newprops = form.props.filter((p, index) => p._id !== id)
        setForm({
            ...form,
            props: newprops,
        })
    }

    const createHandler = async () => {
        setUpdating(true)
        try {
            if (isnew) {
                const data = await SignalsAPI.create(deleteServiceProps(form))
                history.push('/SignalItem/' + data.hash)
            } else {
                const data = await SignalsAPI.updateSignal(form._id, form)

                history.push('/SignalItem/' + hash)
            }
        } catch (e) {
            console.log(e.message)
            setError(e.message)
        }
        setUpdating(false)
    }

    const cancelHandler = () => {
        history.push('/SignalItem/' + hash)
    }

    const deleteServiceProps = (f) => {
        let props = f.props.map((obj) => ({ name: obj.name, value: obj.value }))
        return { ...f, props: props }
    }

    return (
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="12">
                        <Button
                            onClick={createHandler}
                            variant="success"
                            disabled={updating}
                            style={{ float: 'right', marginTop: '1rem' }}
                        >
                            Сохранить
                        </Button>{' '}
                        <Button
                            onClick={cancelHandler}
                            variant="default"
                            style={{ float: 'right', marginTop: '1rem' }}
                        >
                            Отменить
                        </Button>
                        <h1>{isnew ? 'Новая статья' : form.title}</h1>
                        <hr />
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Row>
                                        <Form.Group
                                            as={Col}
                                            controlId="category"
                                        >
                                            <Form.Label>Категория</Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={changeHandler}
                                            >
                                                <option>article</option>
                                                <option>datasets</option>
                                                <option>signals</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            controlId="subcategory"
                                        >
                                            <Form.Label>
                                                Подкатегория
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={changeHandler}
                                            >
                                                {getSubcategoryOptions(
                                                    form.category
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="title">
                                            <Form.Label>Название</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Введите название статьи"
                                                value={form.title}
                                                onChange={changeHandler}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group
                                            as={Col}
                                            controlId="summary"
                                        >
                                            <Form.Label>
                                                Краткое описание
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Введите краткое описание"
                                                value={form.summary}
                                                onChange={changeHandler}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group
                                            as={Col}
                                            controlId="description"
                                        >
                                            <Form.Label>Описание</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="8"
                                                value={form.description}
                                                onChange={changeHandler}
                                            />
                                            {error && (
                                                <Alert variant="warning">
                                                    {error}
                                                </Alert>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Row>
                                        <Form.Group
                                            as={Col}
                                            controlId="propName"
                                        >
                                            <Form.Label>Свойства</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) =>
                                                        setSelectPropName(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option>--</option>
                                                    {getAvailablePropertyOptions(
                                                        form.category,
                                                        form.subcategory
                                                    )}
                                                </Form.Control>
                                                <InputGroup.Append>
                                                    {selectPropName !==
                                                        '--' && (
                                                        <Button
                                                            variant="outline-success"
                                                            onClick={(e) =>
                                                                addPropHandler(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Добавить
                                                        </Button>
                                                    )}
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>

                                    {form.props.map((prop, index) => (
                                        <SinglePropEditor
                                            key={prop._id}
                                            _id={prop._id}
                                            name={prop.name}
                                            value={prop.value}
                                            onChange={(p) =>
                                                changePropHandler(p)
                                            }
                                            onRemove={removePropHandler}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="json">
                                    <Form.Label>JSON</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="8"
                                        onChange={changeJsonHandler}
                                        value={JSON.stringify(
                                            /*deleteServiceProps(form)*/ form,
                                            null,
                                            2
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ArticleEditor

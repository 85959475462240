import React from 'react'

import './App.css'
import { Layout } from 'pages/Layout'
import { Loader } from 'shared/ui/Loader/Loader'
import { useRoutes } from 'app/routes'
import { useAuth } from 'shared/hooks/auth.hook'
import { AuthContext } from 'context/AuthContext'

export function App() {
    const { token, login, logout, userId, ready } = useAuth()
    const isAuthenticated = !!token
    const routes = useRoutes(isAuthenticated)

    if (!ready) {
        return <Loader />
    }

    return (
        <AuthContext.Provider
            value={{
                token,
                login,
                logout,
                userId,
                isAuthenticated,
            }}
        >
            {isAuthenticated}
            <Layout>{routes}</Layout>
        </AuthContext.Provider>
    )
}

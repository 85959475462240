import React, { useContext } from 'react'
import { Link } from 'react-router'

import styles from './TaskFileListItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

const TaskFileListItem = ({card, onClick}) => {

    const clickHandler = (e) => {
        e.preventDefault()
        onClick?.(e.currentTarget.getAttribute('href'))
    }

    return (
        <li className={styles.cardLi} >
            <a
                href={ card.name}  
                onClick={ clickHandler}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                    }}
                >
                    <div className={styles.cardIcon}>
                        <FontAwesomeIcon
                            icon={faFile}
                            className="fa"
                            style={{ color: 'seagreen' }}
                        />
                    </div>
                    <div className={styles.cardBody}>
                        <div className={styles.fileLink}>{card.name}</div>
                        <div className={styles.filePreview}>{card.value !== undefined && card.value}</div>
                    </div>
                </div>
            </a>
        </li>
    )
}

export default TaskFileListItem
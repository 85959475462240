// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n    color: seagreen;\n}\n\n.SignalItem_Title__vFm6O {\n    margin-bottom: 1.5rem;\n}\n\n.SignalItem_Summary__YHNgY {\n    min-width: 300px;\n    max-width: 300px;\n}\n\n.SignalItem_SummaryItem__rnLJs {\n    margin-bottom: 20px;\n    padding: 20px;\n    background-color: #a2bf8a;\n    border-radius: 5px;\n}\n\n.SignalItem_SignalItem__l1Ayf img {\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    padding: 20px;\n}\n\n.SignalItem_SignalItem__l1Ayf p {\n    text-indent: 0; /* 2.5em;*/\n    margin-bottom: 0.7em;\n    text-align: justify;\n}\n", "",{"version":3,"sources":["webpack://./src/features/SignalItem/SignalItem.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,cAAc,EAAE,UAAU;IAC1B,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":["h1 {\n    color: seagreen;\n}\n\n.Title {\n    margin-bottom: 1.5rem;\n}\n\n.Summary {\n    min-width: 300px;\n    max-width: 300px;\n}\n\n.SummaryItem {\n    margin-bottom: 20px;\n    padding: 20px;\n    background-color: #a2bf8a;\n    border-radius: 5px;\n}\n\n.SignalItem img {\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    padding: 20px;\n}\n\n.SignalItem p {\n    text-indent: 0; /* 2.5em;*/\n    margin-bottom: 0.7em;\n    text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Title": "SignalItem_Title__vFm6O",
	"Summary": "SignalItem_Summary__YHNgY",
	"SummaryItem": "SignalItem_SummaryItem__rnLJs",
	"SignalItem": "SignalItem_SignalItem__l1Ayf"
};
export default ___CSS_LOADER_EXPORT___;

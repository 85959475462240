// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignalListItem_cardLi__3yRPm {\n    height: 88px;\n    border-bottom: 1px solid #eee;\n    margin-bottom: 13px;\n    cursor: pointer;\n    white-space: nowrap;\n    color: gray;\n}\n\n.SignalListItem_cardLi__3yRPm:hover {\n    background: #cbe0cf;\n}\n\n.SignalListItem_cardIcon__ZkTVt {\n    border-radius: 4px;\n    min-width: 40px;\n    height: 40px;\n    text-align: center;\n    padding-top: 20px;\n}\n\n.SignalListItem_cardBody__CjDvp {\n    color: #343a40;\n    padding: 1rem 0 1.5em 1.5em;\n    overflow: hidden;\n    flex: 2 1 68px;\n}\n\n.SignalListItem_cardLink__AxNzs {\n    padding: 1rem 0.5rem 1.5em 1.5em;\n    color: gray;\n    text-align: right;\n}\n\n.SignalListItem_deleteIcon__oVn2\\+ {\n    color: white;\n}\n\n.SignalListItem_deleteIcon__oVn2\\+:hover {\n    color: gray;\n}\n", "",{"version":3,"sources":["webpack://./src/entities/Signal/ui/SignalListItem.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".cardLi {\n    height: 88px;\n    border-bottom: 1px solid #eee;\n    margin-bottom: 13px;\n    cursor: pointer;\n    white-space: nowrap;\n    color: gray;\n}\n\n.cardLi:hover {\n    background: #cbe0cf;\n}\n\n.cardIcon {\n    border-radius: 4px;\n    min-width: 40px;\n    height: 40px;\n    text-align: center;\n    padding-top: 20px;\n}\n\n.cardBody {\n    color: #343a40;\n    padding: 1rem 0 1.5em 1.5em;\n    overflow: hidden;\n    flex: 2 1 68px;\n}\n\n.cardLink {\n    padding: 1rem 0.5rem 1.5em 1.5em;\n    color: gray;\n    text-align: right;\n}\n\n.deleteIcon {\n    color: white;\n}\n\n.deleteIcon:hover {\n    color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardLi": "SignalListItem_cardLi__3yRPm",
	"cardIcon": "SignalListItem_cardIcon__ZkTVt",
	"cardBody": "SignalListItem_cardBody__CjDvp",
	"cardLink": "SignalListItem_cardLink__AxNzs",
	"deleteIcon": "SignalListItem_deleteIcon__oVn2+"
};
export default ___CSS_LOADER_EXPORT___;

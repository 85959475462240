import React from 'react'
import styles from './CategoryTab.module.css'

const CategoryTab = ({ title, total, selected, onClick }) => {
    return (
        <div className={styles.tabs} onClick={() => onClick()}>
            <div className={styles.tab + ' ' + (selected && styles.active)}>
                <h2>
                    {title} <span className={styles.total}>{total}</span>
                </h2>
            </div>
        </div>
    )
}

export default CategoryTab

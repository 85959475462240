// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleList_TerminList__d1R1z {\n    list-style: none;\n}\n\n.ArticleList_TerminItem__-VVSw {\n    padding: 0.2em;\n    display: block;\n}\n\n.ArticleList_TerminItem__-VVSw:hover {\n    background-color: #cbe0cf;\n    cursor: pointer;\n}\n\n.ArticleList_active__YCEzL {\n    background-color: green;\n}\n", "",{"version":3,"sources":["webpack://./src/features/Article/ArticleList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".TerminList {\n    list-style: none;\n}\n\n.TerminItem {\n    padding: 0.2em;\n    display: block;\n}\n\n.TerminItem:hover {\n    background-color: #cbe0cf;\n    cursor: pointer;\n}\n\n.active {\n    background-color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerminList": "ArticleList_TerminList__d1R1z",
	"TerminItem": "ArticleList_TerminItem__-VVSw",
	"active": "ArticleList_active__YCEzL"
};
export default ___CSS_LOADER_EXPORT___;

import axios from 'axios'

export const api = axios.create({
    baseURL: process.env.REACT_APP_DATASIG_BACKEND_URL,
    //baseURL: 'http://localhost:3300',
})

const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (error.code === 'ERR_CANCELED') {
        console.log({
            description: 'API canceled!',
        })
        return Promise.resolve()
    }

    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n    color: seagreen;\n}\n\n.Article_Summary__kNg7V {\n    min-width: 300px;\n    max-width: 300px;\n}\n\n.Article_SummaryItem__SJ69m {\n    margin-bottom: 20px;\n    padding: 20px;\n    background-color: #a2bf8a;\n    border-radius: 5px;\n}\n\n.Article_SignalItem__5QLB5 img {\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    padding: 20px;\n}\n\n.Article_SignalItem__5QLB5 p {\n    text-indent: 1.5em;\n    text-align: justify;\n}", "",{"version":3,"sources":["webpack://./src/features/Article/Article.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["h1 {\n    color: seagreen;\n}\n\n.Summary {\n    min-width: 300px;\n    max-width: 300px;\n}\n\n.SummaryItem {\n    margin-bottom: 20px;\n    padding: 20px;\n    background-color: #a2bf8a;\n    border-radius: 5px;\n}\n\n.SignalItem img {\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    padding: 20px;\n}\n\n.SignalItem p {\n    text-indent: 1.5em;\n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Summary": "Article_Summary__kNg7V",
	"SummaryItem": "Article_SummaryItem__SJ69m",
	"SignalItem": "Article_SignalItem__5QLB5"
};
export default ___CSS_LOADER_EXPORT___;

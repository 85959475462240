import React, { useState } from 'react'
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'

import SpectrogramView from './SpectrogramView'

const SignalSpectrogramFlow = ({ data }) => {
    const [updating, setUpdating] = useState(false)
    const [period, setPeriod] = useState(1024)
    const [additive, setAdditive] = useState(10)
    const [multiplicative, setMultiplicative] = useState(2)

    return (
        <>
            {updating ? (
                <Container>
                    <Row style={{ height: '467px', margin: '1rem auto' }}>
                        <Spinner animation="grow" />
                    </Row>
                </Container>
            ) : (
                <>
                    <Row>
                        <Col>
                            {/* <BitView data={data} options={{ period: 256 }} /> */}
                            <SpectrogramView
                                data={data}
                                options={{ period, additive, multiplicative }}
                            />
                            <Form.Row>
                                <Col xs="2">
                                    <RangeSlider
                                        variant="dark"
                                        value={additive}
                                        onChange={(e) =>
                                            setAdditive(e.target.value)
                                        }
                                        min={1}
                                        max={255}
                                    />
                                </Col>
                                <Col xs="2">
                                    <RangeSlider
                                        variant="dark"
                                        value={multiplicative}
                                        onChange={(e) =>
                                            setMultiplicative(e.target.value)
                                        }
                                        min={1}
                                        max={4}
                                    />
                                </Col>
                            </Form.Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default SignalSpectrogramFlow

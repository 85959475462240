import React from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'

const SelectSignalViewer = ({ value, onChange }) => {
    const options = [
        { name: 'Сигнал', value: 0, componentName: 'SignalChart' },
        { name: 'Битовый поток', value: 1, componentName: 'SignalBitView' },
        {
            name: 'Спектрограмма',
            value: 2,
            componentName: 'SignalSpectrogram',
        },
    ]

    const changeHandler = (e) => {
        onChange(options[e.target.value].componentName)
    }

    return (
        <ButtonGroup>
            {options.map((item, index) => (
                <ToggleButton
                    key={index}
                    id={`radio-${index}`}
                    type="radio"
                    variant="outline-success"
                    name="radio"
                    value={index}
                    checked={item.componentName == value}
                    onChange={changeHandler}
                >
                    {item.name}
                </ToggleButton>
            ))}
        </ButtonGroup>
    )
}

export default SelectSignalViewer

import React, { useContext } from 'react'
import logo from './logo.svg'

import { useHistory } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { NavLink } from 'react-router-dom'

export const Layout = (props) => {
    const history = useHistory()
    const auth = useContext(AuthContext)
    const isAuthenticated = !!auth.token

    const logoutHandler = (event) => {
        event.preventDefault()
        auth.logout()
        history.push('/')
    }

    const allwaysLink = (
        <Nav>
            <NavLink to="/datasets" className="nav-link">
                Датасеты
            </NavLink>
            <NavLink to="/signals" className="nav-link">
                Сигналы
            </NavLink>
            <NavLink to="/analitycs" className="nav-link">
                Аналитика
            </NavLink>
            <NavLink to="/about" className="nav-link">
                О проекте
            </NavLink>
        </Nav>
    )
    const authLink = (
        <Nav>
            <NavLink to="/admin/create" className="nav-link">
                Админ
            </NavLink>
        </Nav>
    )

    const isHome = history.location.pathname === '/'

    return (
        <div className="d-flex flex-column vh-100">
            <Navbar
                collapseOnSelect
                expand="lg"
                fixed="top"
                bg="dark"
                variant="dark"
            >
                <Container>
                    <NavLink to="/">
                        <Navbar.Brand>
                            <img
                                alt="OSA logo"
                                src={logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            Datasig
                        </Navbar.Brand>
                    </NavLink>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">{allwaysLink}</Nav>
                        {isAuthenticated && authLink}
                        <Nav>
                            {isAuthenticated ? (
                                <a
                                    className="nav-link"
                                    href="/"
                                    onClick={logoutHandler}
                                >
                                    Выйти
                                </a>
                            ) : (
                                <NavLink to="/login" className="nav-link">
                                    Вход
                                </NavLink>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <main class="flex-shrink-1">{props.children}</main>
            <footer class="footer mt-auto py-3 bg-light">
                <div class="container">
                    <span class="text-muted text-center">
                        <p>&copy; 2023, НИЧ МТУСИ</p>
                    </span>
                </div>
            </footer>
        </div>
    )
}

import SignalListStore from 'shared/stores/SignalListStore'

class AppStore {
    title = 'Root store'
    signal = new SignalListStore(this)
}

const appStore = new AppStore()

export default appStore

export class SignalDataScheme {
    constructor(options) {
        this.termins = options.termins
        this.titles = options.titles
    }

    tooltip(name, attr) {
        if (this.termins[name] !== undefined) {
            if (attr != null) {
                if (this.termins[name][attr] !== undefined)
                    return this.termins[name][attr].descr
                else return name + ': ' + attr
            } else {
                return this.termins[name].descr
            }
        }
        if (attr != null) return name + ': ' + attr
        return name
    }
}

import React from 'react'
import { ButtonGroup, ToggleButton, Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import SignalBitView from 'features/Widgets/SignalBitView'
import SignalChart from 'features/Widgets/SignalChart'
import SignalSpectrogram from 'features/Widgets/SignalSpectrogram'
import SelectSignalDataFormat from './SelectSignalDataFormat'
import SelectSignalViewer from './SelectSignalViewer'

const SelectSignalView = ({ attachment }) => {
    const changeViewerHandler = (viewer) => {
        attachment.setViewer(viewer)
    }

    const changeDataFormatHandler = (format) => {
        attachment.setDataFormat(format)
    }

    return (
        <>
            <Row>
                <Col>
                    <SelectSignalViewer
                        onChange={changeViewerHandler}
                        value={attachment.viewerName}
                    />
                </Col>
                <Col>
                    <SelectSignalDataFormat
                        onChange={changeDataFormatHandler}
                        value={attachment.dataFormat}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {attachment.viewerName == 'SignalChart' && (
                        <SignalChart
                            src={attachment.url}
                            format={attachment.dataFormat}
                        />
                    )}
                    {attachment.viewerName == 'SignalBitView' && (
                        <SignalBitView
                            src={attachment.url}
                            format={attachment.dataFormat}
                        />
                    )}
                    {attachment.viewerName == 'SignalSpectrogram' && (
                        <SignalSpectrogram
                            src={attachment.url}
                            format={attachment.dataFormat}
                        />
                    )}
                </Col>
            </Row>
        </>
    )
}

export default observer(SelectSignalView)

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Dashboard from 'features/Dashboard/Dashboard'

const Analytics = (props) => {
    const fluid = { fluid: window.innerWidth <= 1920 ? true : null }
    return (
        <Container {...fluid}>
            <Row>
                <Col>
                    <h1>Аналитика / Dashboard</h1>
                </Col>
            </Row>

            <Dashboard />
        </Container>
    )
}

export default Analytics

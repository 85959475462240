import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import appStore from 'shared/stores/AppStore'

import styles from './SignalListItem.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileMedicalAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

const SignalListItem = (card) => {
    const auth = useContext(AuthContext)
    const isAuthenticated = !!auth.token

    const removeHandler = (event) => {
        appStore.signal.removeSignal(card._id)
        event.preventDefault()
    }

    return (
        <li className={styles.cardLi}>
            <Link
                to={'/SignalItem/' + card.hash}
                className={{ textDecoration: 'none' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                    }}
                >
                    <div className={styles.cardIcon}>
                        <FontAwesomeIcon
                            icon={faFileMedicalAlt}
                            className="fa-2x"
                            style={{ color: 'gray' }}
                        />
                    </div>
                    <div className={styles.cardBody}>
                        <h5>{card.title}</h5>
                        <p>{card.summary !== undefined && card.summary}</p>
                    </div>
                    <div className={styles.cardLink}>
                        {card.subcategory !== undefined && card.subcategory}
                        <br />
                        {isAuthenticated && (
                            <div className={styles.deleteIcon}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={removeHandler}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default observer(SignalListItem)

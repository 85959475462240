export function getSubCategories(category) {
    if (category === 'article') {
        return [{ _id: 'Termin', count: 0 }]
    }
    if (category === 'datasets') {
        return [
            { _id: 'Imitator', count: 0 },
            { _id: 'RadioRF', count: 0 },
        ]
    }
    if (category === 'signals') {
        return [
            { _id: 'Satellite', count: 0 },
            { _id: 'PAN', count: 0 },
            { _id: 'WAN', count: 0 },
            { _id: 'RadioHF', count: 0 },
            { _id: 'RadioRelay', count: 0 },
            { _id: 'Broadcast', count: 0 },
            { _id: 'Optical', count: 0 },
            { _id: 'Wired', count: 0 },
            { _id: 'Imitator', count: 0 },
            { _id: 'Noise', count: 0 },
        ]
    }
    return []
}

export function getAvailableProperties(category, subcategory) {
    if (category === 'article') {
        return ['Synonim', 'urlFile', 'urlPicture']
    }
    if (category === 'datasets') {
        return [
            'size',
            'SignalFormat',
            'SampleRate',
            'SignalNumber',
            'BandWidth',
            'SNR',
            'Modulation',
            'SymbolNum',
            'ClockRate',
            'FreqTune',
            'FrameMode',
            'FrameLen',
            'urlFile',
            'urlPicture',
        ]
    }
    if (category === 'signals') {
        return [
            'size',
            'Standard',
            'SignalFormat',
            'SampleRate',
            'SignalNumber',
            'BandWidth',
            'SNR',
            'Modulation',
            'SymbolNum',
            'SignalDuplex',
            'ClockRate',
            'FreqTune',
            'FrameMode',
            'FrameLen',
            'urlFile',
            'urlPicture',
        ]
    }
    return []
}

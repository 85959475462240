import React from 'react'
import { Row, Col } from 'react-bootstrap'

const ToolbarTopPanel = (props) => {
    return (
        <div
            className="position-absolute top-0 end-0"
            style={{ paddingTop: '80px', paddingRight: '1rem' }}
        >
            {props.children}
        </div>
    )
}

export default ToolbarTopPanel

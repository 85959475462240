import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import { App } from 'app'

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(app)

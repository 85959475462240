import React, { useEffect, useRef } from 'react'
import './PcmChart.scss'
import { chart } from './chart'

const PcmChart = ({ data, onSliderChange, sliderWidth }) => {
    const viewRef = useRef(null)

    useEffect(() => {
        var tgChart
        if (data != undefined) {
            tgChart = chart(viewRef.current, data, {
                onSliderChange: (left, width) => {
                    if (onSliderChange !== undefined)
                        onSliderChange(left, width)
                },
                DEFAULT_WIDTH_PERCENT: sliderWidth,
            })
            tgChart.init()
        }

        return () => {
            if (tgChart !== undefined) tgChart.destroy()
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-chart">
            <div className="card">
                <div className="tg-chart" ref={viewRef}>
                    <div data-el="tooltip" className="tg-chart-tooltip"></div>
                    <canvas data-el="main"></canvas>

                    <div className="tg-chart-slider" data-el="slider">
                        <canvas></canvas>
                        <div data-el="left" className="tg-chart-slider__left">
                            <div
                                className="tg-chart-slider__arrow--left"
                                data-el="arrow"
                                data-type="left"
                            ></div>
                        </div>

                        <div
                            data-el="window"
                            data-type="window"
                            className="tg-chart-slider__window"
                        ></div>

                        <div data-el="right" className="tg-chart-slider__right">
                            <div
                                className="tg-chart-slider__arrow--right"
                                data-el="arrow"
                                data-type="right"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PcmChart

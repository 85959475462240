// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignalListSearchInput_cardUl__CBxUe {\n    list-style: none;\n    padding: 0 0 10px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/features/SignalListSearchInput/SignalListSearchInput.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".cardUl {\n    list-style: none;\n    padding: 0 0 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardUl": "SignalListSearchInput_cardUl__CBxUe"
};
export default ___CSS_LOADER_EXPORT___;

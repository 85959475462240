import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { SignalsAPI } from 'shared/api'
import SingleFilter from './SingleFilter'

const FilterPanel = (props) => {
    const [updating, setUpdating] = useState(true)
    const [filters, setFilters] = useState(null)

    useEffect(() => {
        SignalsAPI.getFilters({
            searchText: props.searchText,
            category: props.category,
            subcategory: props.subcategory,
        }).then((filters) => {
            setFilters(filters)
            setUpdating(false)
        })
    }, [updating, props.searchText, props.category, props.subcategory])

    return (
        props.show && (
            <>
                {updating ? (
                    '...'
                ) : (
                    <Col
                        lg="3"
                        style={{
                            textAlign: 'right',
                            borderLeft: '1px solid rgba(0,0,0,.125)',
                        }}
                    >
                        <SingleFilter
                            filter={filters.find(
                                (p) => p._id === 'SignalNumber'
                            )}
                        />

                        <SingleFilter
                            filter={filters.find(
                                (p) => p._id === 'SignalFormat'
                            )}
                        />

                        <SingleFilter
                            filter={filters.find((p) => p._id === 'SampleRate')}
                        />

                        <SingleFilter
                            filter={filters.find((p) => p._id === 'Modulation')}
                        />
                        <SingleFilter
                            filter={filters.find((p) => p._id === 'FrameMode')}
                        />
                        <SingleFilter
                            filter={filters.find((p) => p._id === 'FrameLen')}
                        />

                        {/* {filters.map((filter, index) => (
                            <SingleFilter key={index} filter={filter} />
                        ))} */}
                    </Col>
                )}
            </>
        )
    )
}

export default FilterPanel

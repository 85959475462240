import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { TerminContextProvider } from '../context/TerminContext'

import About from 'pages/About'
import Auth from 'pages/Auth'
import Analytics from 'pages/Analytics'
import Datasets from 'pages/Datasets'
import Home from 'pages/Home'

import SignalItem from 'features/SignalItem/SignalItem'
import SignalLoader from 'features/SignalLoader/SignalLoader'
import Article from 'features/Article/Article'
import ArticleEditor from 'features/Article/ArticleEditor'
import SpectrogramLive from 'features/SpectrogramLive/SpectrogramLive'
import DatabaseExport from 'features/DatabaseExport/DatabaseExport'
import FileUploadPage from 'pages/FileUploadPage/FileUploadPage'

export const useRoutes = (isAuthenticated) => {
    return (
        <TerminContextProvider>
            <Switch>
                {!isAuthenticated && (
                    <Route path="/login" exact>
                        <Auth />
                    </Route>
                )}

                <Route path="/about" component={About} />
                <Route
                    path="/datasets/:subcategory"
                    render={(props) => (
                        <Datasets {...props} category="datasets" />
                    )}
                />
                <Route
                    path="/datasets"
                    render={(props) => (
                        <Datasets {...props} category="datasets" />
                    )}
                />
                <Route
                    path="/signals/:subcategory"
                    render={(props) => (
                        <Datasets {...props} category="signals" />
                    )}
                />
                <Route
                    path="/signals"
                    render={(props) => (
                        <Datasets {...props} category="signals" />
                    )}
                />

                <Route
                    path="/articles"
                    render={(props) => (
                        <Datasets {...props} category="article" />
                    )}
                />

                <Route path="/signalitem/:id" component={SignalItem} />

                <Route path="/item/:id" component={Article} />

                <Route path="/analitycs" component={Analytics} />

                <Route path="/live" component={SpectrogramLive} />

                {isAuthenticated && (
                    <Route
                        path="/admin/edit/:hash"
                        render={(props) => <ArticleEditor {...props} />}
                    />
                )}

                {isAuthenticated && (
                    <Route path="/admin/create2" component={ArticleEditor} />
                )}

                {isAuthenticated && (
                    <Route path="/admin/upload" component={FileUploadPage} />
                )}

                {isAuthenticated && (
                    <Route path="/admin/create" component={SignalLoader} />
                )}

                {isAuthenticated && (
                    <Route path="/admin/export" component={DatabaseExport} />
                )}

                <Route path="/" exact component={Home} />

                {isAuthenticated ? (
                    <Redirect to="/" />
                ) : (
                    <Redirect to="/login" />
                )}
            </Switch>
        </TerminContextProvider>
    )
}

import React, { useState } from 'react'
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SignalsAPI } from 'shared/api'
import sample from './sample.json'

const SignalLoader = () => {
    //const [signalSample, setSignalSample] = useState(require('./sample.json'))
    const [form, setForm] = useState({
        signal: JSON.stringify(sample, null, 2),
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value })
    }

    const createHandler = async () => {
        try {
            setLoading(true)
            const data = await SignalsAPI.create({ ...form })
            setLoading(false)
        } catch (e) {
            setError(e.message)
        }
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <h1>Создание сигнала</h1>
                    <hr />
                    <Link style={{ float: 'right' }} to="/admin/create2">
                        Новый интерфейс
                    </Link>
                    <Form>
                        <Form.Group controlId="signal">
                            <Form.Label>JSON Signal</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="20"
                                value={form.signal}
                                onChange={changeHandler}
                            />
                            {error && <Alert variant="warning">{error}</Alert>}
                        </Form.Group>
                        <Button
                            onClick={createHandler}
                            variant="success"
                            disabled={loading}
                        >
                            Create
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default SignalLoader

import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import useAutosizeTextArea from 'shared/hooks/textarea.hook'
import styles from './PropertyInput.module.css'

const PropertyInput = (props) => {
    const [editable, setEditable] = useState(false)

    const inputRef = useRef(null)
    const textareaRef = useRef(null)

    useEffect(() => {
        setEditable(props.editable)
    }, [props.editable])

    useEffect(() => {
        if (editable && inputRef.current) inputRef.current.select()
        if (editable && textareaRef.current) textareaRef.current.select()
    }, [editable])

    let editableValue = () => {
        if (props.option && props.option.property)
            return props.name + ': ' + props.value

        return props.value
    }

    const changeHandler = (e) => {
        if (props.option && props.option.property) {
            changePair(e.target.value)
            return
        }

        props.onChange(props.id, props.name, e.target.value)
    }

    const changePair = (pair) => {
        const s = pair.split(':')
        let p1 = s[0] && s[0].trim()
        let p2 = s[1] && s[1].trimStart()
        if (s.length < 2) {
            p1 = ''
            p2 = p1
        }
        props.onChange(props.id, p1, p2)
    }

    const handleKeyDown = (e) => {
        const cursorPos = e.target.selectionStart
        const cursorMax = e.target.value.length
        if (e.key === 'Enter') {
            if (cursorPos === 0 && e.target.selectionEnd === cursorMax)
                //props.onEnter()
                //setEditable(false)
                props.onNext()
        }
        if (e.key === 'ArrowUp') {
            if (cursorPos == 0) props.onPrev()
        }
        if (e.key === 'ArrowDown') {
            if (e.target.selectionEnd === cursorMax) props.onNext()
        }
    }

    const blurHandler = (e) => {
        setEditable(false)
    }

    const focusHandler = (e) => {
        //e.target.select()
    }

    const clickHandler = (e) => {
        props.onFocus(props.id)
        setEditable(true)
    }

    // Автоподстройка высоты textArea
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '0px'
            const scrollHeight = textareaRef.current.scrollHeight

            textareaRef.current.style.height = scrollHeight + 'px'
        }
    }, [editable, textareaRef.current, props.value])

    if (props.name === '' && !editable)
        return (
            <div className={styles.propertyItem}>
                <span className={styles.propertyKey}>*null</span>
            </div>
        )

    if (props.name === 'description')
        return (
            <div
                className={`${styles.propertyItem} ${styles[props.name]}`}
                onClick={clickHandler}
            >
                <textarea
                    className={styles.propertyTextarea}
                    readOnly={!editable}
                    ref={textareaRef}
                    value={props.value}
                    onChange={changeHandler}
                    onKeyDown={handleKeyDown}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    rows="1"
                />
            </div>
        )

    return editable ? (
        <div className={`${styles.propertyItem} ${styles[props.name]}`}>
            {!(props.option && props.option.property) && (
                <span className={`${styles.propertyKey}`}>{props.name}</span>
            )}
            <span className={`${styles.propertyValue}`}>
                <input
                    ref={inputRef}
                    type="text"
                    value={editableValue()}
                    className={`${styles.propertyInput} `}
                    onKeyDown={handleKeyDown}
                    onChange={changeHandler}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                />
            </span>
        </div>
    ) : (
        <div
            className={`${styles.propertyItem} ${styles[props.name]}`}
            onClick={clickHandler}
        >
            {props.option && props.option.property && (
                <span className={`${styles.propertyKey}`}>{props.name}</span>
            )}
            <span className={`${styles.propertyValue}`}>{props.value}</span>
        </div>
    )
}

export default observer(PropertyInput)

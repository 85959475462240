import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import io from 'socket.io-client'
import SignalSpectrogramFlow from '../Widgets/SignalSpectrogramFlow'
import PcmChart from '../Widgets/PcmChart'

const SpectrogramLive = (props) => {
    const pos = useRef(0)
    const [buffer, setBuffer] = useState({
        data: new Uint16Array(new ArrayBuffer(2 * 1024 * 400)),
        chartData: null,
        pos: 0,
    })

    const socketHandler = (message) => {
        var d = message.v1

        var partsize = buffer.data.length - d.length

        for (var i = 0; i < partsize; i++)
            buffer.data[buffer.data.length - i] =
                buffer.data[buffer.data.length - d.length - i]

        for (var j = 0; j < d.length; j++) buffer.data[j] = d[j]

        var newpos = pos.current + 1
        if (newpos >= 400) newpos = 0

        pos.current = newpos
        //setBuffer({ pos: newpos })
        setBuffer({ chartData: toChartData(d) })
    }

    useEffect(() => {
        const socket = io(
            '' //'http://localhost:5000'
            // , {
            //     withCredentials: true,
            //     extraHeaders: {
            //         'my-custom-header': 'abcd',
            //     },
            // }
        )

        socket.on('SpectrogrammData', socketHandler)
    }, [])

    function toChartData(b) {
        const meteoData = {
            columns: [['x'], ['y0']],
            types: {
                y0: 'line',
                x: 'x',
            },
            names: {
                y0: 'ГАЛС-ВЭ',
            },
            colors: {
                // y0: '#619636',
                y0: '#39C78D',
                y1: 'seagreen',
            },
        }

        if (b != undefined) {
            var d = new Date('2020-12-24T16:16:06')
            for (var j = 0; j < b.length; j++) {
                meteoData.columns[0].push(j)
                meteoData.columns[1].push(b[j])
            }
        }
        return meteoData
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Live</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PcmChart
                        index={12}
                        data={buffer.chartData}
                        sliderWidth="1"
                    />
                    <SignalSpectrogramFlow data={buffer.data} />
                </Col>
            </Row>
        </Container>
    )
}

export default SpectrogramLive

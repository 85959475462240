import { css } from './utils'

export function bit(root, data, opt) {
    var options = {}

    options.root = root
    options.scroll = { x: 0, y: 0 }
    options.period = 1024

    options = { ...options, ...opt }

    const canvas = root.querySelector('[data-el="main"]')
    const ctx = canvas.getContext('2d')

    let raf

    updateBoundaries()
    subscribeEvents(false)
    const proxy = createProxy()

    function createProxy() {
        return new Proxy(
            {},
            {
                set(...args) {
                    const result = Reflect.set(...args)
                    raf = requestAnimationFrame(paint)
                    return result
                },
            }
        )
    }

    function subscribeEvents(removeSubscribe) {
        if (removeSubscribe) {
            canvas.removeEventListener('mousemove', mousemove)
            canvas.removeEventListener('mouseleave', mouseleave)
            canvas.removeEventListener('mousedown', mousedown)
            canvas.removeEventListener('mouseup', mouseup)
            canvas.onwheel = null
            window.removeEventListener('resize', updateBoundaries)
        } else {
            window.addEventListener('resize', updateBoundaries)
            canvas.addEventListener('mousemove', mousemove)
            canvas.addEventListener('mousedown', mousedown)
            canvas.addEventListener('mouseup', mouseup)
            canvas.addEventListener('mouseleave', mouseleave)
            canvas.onwheel = mousewheel
        }
    }

    function mousemove({ clientX, clientY }) {
        const { left, top } = canvas.getBoundingClientRect()
        proxy.mouse = {
            x: (clientX - left) * 2,
            tooltip: {
                left: clientX - left,
                top: clientY - top,
            },
        }
    }

    function mousedown(event) {
        const startX = event.pageX + options.scroll.x
        const startY = event.pageY + options.scroll.y

        document.onmousemove = (e) => {
            const deltaX = startX - e.pageX
            const deltaY = startY - e.pageY
            if (deltaX < 0 || deltaY < 0) {
                return
            }

            options.scroll.x = deltaX
            options.scroll.y = deltaY
            proxy.scroll = options.scroll
        }
    }

    function mousewheel(event) {
        const delta = Math.sign(event.deltaY) * 1

        options.period = options.period + delta
        proxy.period = options.period

        return false
    }

    function mouseup() {
        document.onmousemove = null
    }

    function mouseleave() {
        proxy.mouse = null
    }

    function updateBoundaries() {
        options.WIDTH = root.clientWidth
        options.HEIGHT = 400
        options.DPI_WIDTH = options.WIDTH
        options.DPI_HEIGHT = options.HEIGHT * 1

        canvas.width = options.DPI_WIDTH
        canvas.height = options.DPI_HEIGHT
        css(canvas, {
            width: options.WIDTH + 'px',
            height: options.HEIGHT + 'px',
        })

        paint()
    }

    function clear() {
        ctx.clearRect(0, 0, options.DPI_WIDTH, options.DPI_HEIGHT)
    }

    function paint() {
        clear()

        if (data === undefined) return

        var imageData = ctx.getImageData(
            0,
            0,
            options.DPI_WIDTH,
            options.DPI_HEIGHT
        )

        var buf = new ArrayBuffer(imageData.data.length)
        var buf8 = new Uint8ClampedArray(buf)
        var view = new Uint32Array(buf)

        let value, color

        for (var y = 0; y < options.DPI_HEIGHT; ++y) {
            for (var x = 0; x < options.DPI_WIDTH; ++x) {
                const bytePos = ~~(
                    ((options.scroll.y + y) * options.period +
                        x +
                        options.scroll.x) /
                    8
                )

                const bitPos =
                    ((options.scroll.y + y) * options.period +
                        x +
                        options.scroll.x) %
                    8

                if (
                    x + options.scroll.x <= options.period &&
                    bytePos <= data.length
                ) {
                    value = (data[bytePos] >> (7 - bitPos)) & 1
                    color = value === 1 ? 0xff8dc739 : 0
                } else color = 0

                view[y * options.DPI_WIDTH + x] = color
            }
        }

        imageData.data.set(buf8)
        ctx.putImageData(imageData, 0, 0)
    }

    return {
        setOptions(opt) {
            options.period = opt.period
            updateBoundaries()
        },
        destroy() {
            cancelAnimationFrame(raf)
            subscribeEvents(false)
        },
    }
}

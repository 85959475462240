import { api } from './configs/axios.config'

export const UploadAPI = {
    upload: async function (file) {
        const formData = new FormData()
        formData.append('filename', file)

        const response = await api.request({
            url: `/api/v1/upload`,
            method: 'POST',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            },
        })

        console.log(response.data)

        return response.data
    },
}

import { css } from './utils'
import { palette } from 'shared/lib/spectr_palette/palette'

export function spectrogram(root, data, opt) {
    var options = {}

    options.root = root
    options.scroll = { x: 0, y: 0 }
    options.period = 1024
    options.zoom = 1

    options = { ...options, ...opt }

    const canvas = root.querySelector('[data-el="main"]')
    const ctx = canvas.getContext('2d')

    let raf

    updateBoundaries()
    subscribeEvents(false)
    const proxy = createProxy()

    function createProxy() {
        return new Proxy(
            {},
            {
                set(...args) {
                    const result = Reflect.set(...args)
                    raf = requestAnimationFrame(paint)
                    return result
                },
            }
        )
    }

    function subscribeEvents(removeSubscribe) {
        if (removeSubscribe) {
            canvas.removeEventListener('mousemove', mousemove)
            canvas.removeEventListener('mouseleave', mouseleave)
            canvas.removeEventListener('mousedown', mousedown)
            canvas.removeEventListener('mouseup', mouseup)
            canvas.onwheel = null
            window.removeEventListener('resize', updateBoundaries)
        } else {
            window.addEventListener('resize', updateBoundaries)
            canvas.addEventListener('mousemove', mousemove)
            canvas.addEventListener('mousedown', mousedown)
            canvas.addEventListener('mouseup', mouseup)
            canvas.addEventListener('mouseleave', mouseleave)
            canvas.onwheel = mousewheel
        }
    }

    function mousemove({ clientX, clientY }) {
        const { left, top } = canvas.getBoundingClientRect()
        proxy.mouse = {
            x: (clientX - left) * 2,
            tooltip: {
                left: clientX - left,
                top: clientY - top,
            },
        }
    }

    function mousedown(event) {
        const startX = event.pageX + options.scroll.x
        const startY = event.pageY + options.scroll.y

        document.onmousemove = (e) => {
            const deltaX = startX - e.pageX
            const deltaY = startY - e.pageY
            if (deltaX < 0 || deltaY < 0) {
                return
            }

            options.scroll.x = deltaX
            options.scroll.y = deltaY
            proxy.scroll = options.scroll
        }
    }

    function mousewheel(event) {
        const { left, top, bottom, right } = canvas.getBoundingClientRect()
        const delta = Math.sign(event.deltaY) * 1
        const p = ((event.clientX - left) / (right - left)) * options.period

        if (delta < 0) {
            options.zoom = options.zoom * 2
        } else {
            options.zoom = 1
        }

        if (options.zoom < 1) {
            options.zoom = 1
            return
        }
        if (options.zoom > 8) {
            options.zoom = 8
            return
        }

        if (options.zoom != 1) {
            options.scroll.x = Math.floor(options.scroll.x + p / options.zoom)
        } else {
            options.scroll.x = 0
        }

        updateBoundaries()
        //proxy.zoom = options.zoom

        return false
    }

    function mouseup() {
        document.onmousemove = null
    }

    function mouseleave() {
        proxy.mouse = null
    }

    function updateBoundaries() {
        options.WIDTH = root.clientWidth
        options.HEIGHT = 400
        options.DPI_WIDTH = options.period / options.zoom
        options.DPI_HEIGHT = options.HEIGHT * 1

        canvas.width = options.DPI_WIDTH
        canvas.height = options.DPI_HEIGHT
        css(canvas, {
            width: options.WIDTH + 'px',
            height: options.HEIGHT + 'px',
        })

        paint()
    }

    function clear() {
        ctx.clearRect(0, 0, options.DPI_WIDTH, options.DPI_HEIGHT)
    }

    var max = 0
    for (var i = 0; i < data.length; i++) if (data[i] > max) max = data[i]

    function paint() {
        clear()

        if (data === undefined) return

        var imageData = ctx.getImageData(
            0,
            0,
            options.DPI_WIDTH,
            options.DPI_HEIGHT
        )

        var buf = new ArrayBuffer(imageData.data.length)
        var buf8 = new Uint8ClampedArray(buf)
        var view = new Uint32Array(buf)

        let value, color
        const syn = 0

        function mapcolor(v) {
            var c = Math.floor(
                (v * 256 * options.multiplicative) / 2000 + options.additive
            )
            if (c > 255) c = 255
            c = palette[c]

            return 0xff000000 | c

            // (255 << 24) | // alpha
            // (value << 16) | // blue
            // (0 << 8) | // green
            // 0 // red
        }

        for (var y = 0; y < options.DPI_HEIGHT; ++y) {
            for (var x = 0; x < options.DPI_WIDTH; ++x) {
                // const bytePos = ~~(
                //     ((options.scroll.y + y) * options.period +
                //         x +
                //         options.scroll.x) /
                //     8
                // )
                const bytePos =
                    (options.scroll.y + y) * options.period +
                    x +
                    options.scroll.x

                const bitPos =
                    ((options.scroll.y + y) * options.period +
                        x +
                        options.scroll.x) %
                    8

                if (
                    x + options.scroll.x <= options.period &&
                    bytePos <= data.length
                ) {
                    value = (data[bytePos] >> (7 - bitPos)) & 1
                    color = mapcolor(data[bytePos])
                    //color = value === 1 ? 0xff8dc739 : 0
                } else color = 0

                view[y * options.DPI_WIDTH + x] = color
                // (255 << 24) | // alpha
                // (value << 16) | // blue
                // (0 << 8) | // green
                // 0 // red
            }
        }

        imageData.data.set(buf8)
        ctx.putImageData(imageData, 0, 0)
    }

    return {
        setOptions(opt) {
            options.period = opt.period
            options.additive = parseInt(opt.additive)
            options.multiplicative = parseInt(opt.multiplicative)
            updateBoundaries()
        },
        destroy() {
            cancelAnimationFrame(raf)
            subscribeEvents(false)
        },
    }
}

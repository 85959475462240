// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskFileListItem_cardLi__fjIRV {\n    cursor: pointer;\n    white-space: nowrap;\n    color: gray;\n}\n\n.TaskFileListItem_cardLi__fjIRV:hover {\n    background: #cbe0cf;\n}\n\n.TaskFileListItem_cardIcon__U8tLV {\n    border-radius: 4px;\n    min-width: 20px;\n    text-align: left;\n    padding-top: 0px;\n}\n\n.TaskFileListItem_cardBody__BjiVd {\n    color: #343a40;\n    overflow: hidden;\n    flex: 2 1 68px;\n}\n\n.TaskFileListItem_fileLink__CWtlc {\n    color: seagreen;\n    text-decoration: underline;\n}\n\n.TaskFileListItem_filePreview__RlBvf {\n    color: gray;\n    font-style: italic;\n    padding-bottom: 0.5rem;\n}\n\n.TaskFileListItem_deleteIcon__mUoQ\\+ {\n    color: white;\n}\n\n.TaskFileListItem_deleteIcon__mUoQ\\+:hover {\n    color: gray;\n}", "",{"version":3,"sources":["webpack://./src/entities/TaskFile/ui/TaskFileListItem.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".cardLi {\n    cursor: pointer;\n    white-space: nowrap;\n    color: gray;\n}\n\n.cardLi:hover {\n    background: #cbe0cf;\n}\n\n.cardIcon {\n    border-radius: 4px;\n    min-width: 20px;\n    text-align: left;\n    padding-top: 0px;\n}\n\n.cardBody {\n    color: #343a40;\n    overflow: hidden;\n    flex: 2 1 68px;\n}\n\n.fileLink {\n    color: seagreen;\n    text-decoration: underline;\n}\n\n.filePreview {\n    color: gray;\n    font-style: italic;\n    padding-bottom: 0.5rem;\n}\n\n.deleteIcon {\n    color: white;\n}\n\n.deleteIcon:hover {\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardLi": "TaskFileListItem_cardLi__fjIRV",
	"cardIcon": "TaskFileListItem_cardIcon__U8tLV",
	"cardBody": "TaskFileListItem_cardBody__BjiVd",
	"fileLink": "TaskFileListItem_fileLink__CWtlc",
	"filePreview": "TaskFileListItem_filePreview__RlBvf",
	"deleteIcon": "TaskFileListItem_deleteIcon__mUoQ+"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'
import styles from './PiePanel.module.css'

const PiePanel = (props) => {
    const dataMock = [
        { title: 'Sattelite', value: 10, color: '#E38627' },
        { title: 'Imitator', value: 15, color: '#C13C37' },
        { title: 'RadioHF', value: 20, color: '#6A2135' },
    ]

    const dataMock2 = [
        { title: 'FM', value: 10, color: '#038627' },
        { title: 'QAM', value: 40, color: '#E38627' },
        { title: 'PSK', value: 5, color: '#C13C37' },
        { title: 'OFDM', value: 20, color: '#6A2135' },
    ]

    return (
        <Row className="justify-content-md-center">
            <Col lg="4" xs>
                <div className={styles.piePanel}>
                    <PieChart
                        style={{ verticalAlign: 'top' }}
                        label={({ dataEntry }) => dataEntry.title}
                        startAngle={180}
                        lengthAngle={180}
                        viewBoxSize={[100, 50]}
                        labelStyle={(index) => ({
                            fill: dataMock[index].color,
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                        })}
                        radius={30}
                        labelPosition={112}
                        data={dataMock}
                    />
                    <div className={styles.pieTitle}>ИСТОЧНИК</div>
                </div>
            </Col>
            <Col lg="4" xs>
                <div className={styles.piePanel}>
                    <PieChart
                        lineWidth="20"
                        data={[
                            { title: 'Signals', value: 10, color: '#E38627' },
                            { title: 'Dataset', value: 15, color: '#C13C37' },
                        ]}
                        labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#E38627',
                        }}
                        label={() => ''}
                        labelPosition={0}
                    ></PieChart>
                    <div className={styles.chartInnerLabel}>
                        ВСЕГО
                        <br />
                        СИГНАЛОВ
                        <div className={styles.chartInnerValue}>235818</div>
                    </div>
                </div>
            </Col>
            <Col lg="4" xs>
                <div className={styles.PiePanel}>
                    <PieChart
                        label={({ dataEntry }) => dataEntry.title}
                        startAngle={180}
                        lengthAngle={180}
                        viewBoxSize={[100, 50]}
                        labelStyle={(index) => ({
                            fill: dataMock2[index].color,
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                        })}
                        radius={32}
                        labelPosition={112}
                        data={dataMock2}
                    />
                    <div className={styles.pieTitle}>Вид модуляции</div>
                </div>
            </Col>
        </Row>
    )
}

export default PiePanel

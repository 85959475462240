import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    InputGroup,
    FormControl,
    Form,
    Spinner,
} from 'react-bootstrap'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import BitView from './BitView'
import ErrorPanel from 'shared/ui/Alerts/ErrorPanel'

const SignalBitView = ({ format, src }) => {
    const [updating, setUpdating] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [period, setPeriod] = useState(1024)

    useEffect(() => {
        function load() {
            var url = src
            console.log(format)

            var oReq = new XMLHttpRequest()
            oReq.open('GET', url, true)
            oReq.responseType = 'arraybuffer'

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response // Note: not oReq.responseText
                if (oReq.status != 200) {
                    setError('Файл недоступен')
                    return
                }
                if (arrayBuffer) {
                    var byteArray

                    if (format === 'word')
                        byteArray = new Uint16Array(arrayBuffer)
                    else if (format === 'short')
                        byteArray = new Int16Array(arrayBuffer)
                    else if (format === 'byte')
                        byteArray = new Uint8Array(arrayBuffer)
                    else byteArray = new Int16Array(arrayBuffer)

                    setData(byteArray)
                    setUpdating(false)
                }
            }

            oReq.onerror = function () {
                setError('Файл недоступен')
            }

            oReq.send()
        }
        setUpdating(true)
        load()
    }, [src, format]) // eslint-disable-line react-hooks/exhaustive-deps

    if (error) return <ErrorPanel message={error} />

    return (
        <>
            {updating ? (
                <Container>
                    <Row style={{ height: '467px', margin: '1rem auto' }}>
                        <Spinner animation="grow" />
                    </Row>
                </Container>
            ) : (
                <>
                    <Row>
                        <Col>
                            <BitView data={data} options={{ period }} />
                            <Col xs="2">
                                <InputGroup>
                                    <FormControl
                                        placeholder="Period"
                                        value={period}
                                        onChange={(e) => {
                                            setPeriod(
                                                parseInt(e.target.value, 10)
                                            )
                                        }}
                                    />
                                    <Button
                                        variant="dark"
                                        onClick={() => {
                                            setPeriod(period + 1)
                                            return false
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <Button
                                        variant="dark"
                                        onClick={() => {
                                            setPeriod(period - 1)
                                            return false
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default SignalBitView

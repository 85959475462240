import React from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'

const DatabaseExport = () => {
    const downloadUrl = (url, body, exportFileName) => {
        fetch(url, {
            method: 'POST',
            body,
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', exportFileName)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            })
    }

    const onExport = () => {
        downloadUrl(
            '/api/admin/exportToMarkdown',
            JSON.stringify({
                category: 'signals',
            }),
            `datasig.zip`
        )
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Экспорт</h1>
                    <h3>Markdown</h3>
                    <p>
                        Экспортировать базу описаний сигналов в архив файлов в
                        формате Markdown
                    </p>
                    <Button onClick={onExport}>Экспорт</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default DatabaseExport

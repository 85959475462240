export const palette = [
    0 | (0 << 8) | (0 << 16),
    0 | (13 << 8) | (25 << 16),
    0 | (13 << 8) | (25 << 16),
    0 | (12 << 8) | (25 << 16),
    0 | (12 << 8) | (25 << 16),
    0 | (12 << 8) | (25 << 16),
    0 | (12 << 8) | (25 << 16),
    1 | (11 << 8) | (25 << 16),
    1 | (11 << 8) | (25 << 16),
    1 | (11 << 8) | (26 << 16),
    1 | (10 << 8) | (26 << 16),
    1 | (10 << 8) | (26 << 16),
    1 | (10 << 8) | (26 << 16),
    1 | (9 << 8) | (27 << 16),
    1 | (9 << 8) | (27 << 16),
    1 | (9 << 8) | (27 << 16),
    1 | (9 << 8) | (28 << 16),
    1 | (8 << 8) | (28 << 16),
    1 | (8 << 8) | (29 << 16),
    1 | (8 << 8) | (29 << 16),
    1 | (7 << 8) | (29 << 16),
    1 | (7 << 8) | (30 << 16),
    1 | (7 << 8) | (30 << 16),
    1 | (6 << 8) | (31 << 16),
    1 | (6 << 8) | (31 << 16),
    1 | (6 << 8) | (32 << 16),
    1 | (5 << 8) | (32 << 16),
    1 | (5 << 8) | (33 << 16),
    1 | (4 << 8) | (33 << 16),
    1 | (4 << 8) | (34 << 16),
    1 | (3 << 8) | (35 << 16),
    1 | (3 << 8) | (35 << 16),
    1 | (3 << 8) | (36 << 16),
    1 | (2 << 8) | (36 << 16),
    1 | (2 << 8) | (37 << 16),
    1 | (1 << 8) | (38 << 16),
    1 | (1 << 8) | (38 << 16),
    2 | (1 << 8) | (39 << 16),
    2 | (1 << 8) | (40 << 16),
    3 | (1 << 8) | (41 << 16),
    4 | (1 << 8) | (41 << 16),
    5 | (1 << 8) | (42 << 16),
    5 | (1 << 8) | (43 << 16),
    6 | (1 << 8) | (44 << 16),
    7 | (1 << 8) | (44 << 16),
    8 | (1 << 8) | (45 << 16),
    8 | (1 << 8) | (46 << 16),
    9 | (1 << 8) | (47 << 16),
    10 | (1 << 8) | (48 << 16),
    11 | (1 << 8) | (49 << 16),
    12 | (1 << 8) | (50 << 16),
    13 | (1 << 8) | (51 << 16),
    14 | (1 << 8) | (51 << 16),
    15 | (1 << 8) | (52 << 16),
    16 | (1 << 8) | (53 << 16),
    17 | (1 << 8) | (54 << 16),
    18 | (1 << 8) | (55 << 16),
    20 | (1 << 8) | (56 << 16),
    21 | (1 << 8) | (57 << 16),
    22 | (1 << 8) | (58 << 16),
    23 | (1 << 8) | (59 << 16),
    25 | (1 << 8) | (60 << 16),
    26 | (1 << 8) | (62 << 16),
    27 | (1 << 8) | (63 << 16),
    29 | (1 << 8) | (64 << 16),
    30 | (1 << 8) | (65 << 16),
    32 | (1 << 8) | (66 << 16),
    33 | (1 << 8) | (67 << 16),
    35 | (1 << 8) | (68 << 16),
    37 | (2 << 8) | (69 << 16),
    38 | (2 << 8) | (71 << 16),
    40 | (2 << 8) | (72 << 16),
    42 | (2 << 8) | (73 << 16),
    44 | (2 << 8) | (74 << 16),
    46 | (2 << 8) | (75 << 16),
    47 | (2 << 8) | (77 << 16),
    49 | (2 << 8) | (78 << 16),
    51 | (2 << 8) | (79 << 16),
    53 | (2 << 8) | (81 << 16),
    56 | (2 << 8) | (82 << 16),
    58 | (2 << 8) | (83 << 16),
    60 | (2 << 8) | (84 << 16),
    62 | (2 << 8) | (86 << 16),
    64 | (2 << 8) | (87 << 16),
    67 | (2 << 8) | (89 << 16),
    69 | (2 << 8) | (90 << 16),
    72 | (2 << 8) | (91 << 16),
    74 | (2 << 8) | (93 << 16),
    77 | (2 << 8) | (94 << 16),
    79 | (2 << 8) | (96 << 16),
    82 | (2 << 8) | (97 << 16),
    85 | (2 << 8) | (99 << 16),
    87 | (2 << 8) | (100 << 16),
    90 | (2 << 8) | (102 << 16),
    93 | (2 << 8) | (103 << 16),
    96 | (2 << 8) | (105 << 16),
    99 | (2 << 8) | (106 << 16),
    102 | (2 << 8) | (108 << 16),
    105 | (2 << 8) | (109 << 16),
    108 | (2 << 8) | (111 << 16),
    112 | (2 << 8) | (112 << 16),
    114 | (2 << 8) | (113 << 16),
    116 | (2 << 8) | (113 << 16),
    117 | (2 << 8) | (113 << 16),
    119 | (2 << 8) | (112 << 16),
    120 | (2 << 8) | (112 << 16),
    122 | (2 << 8) | (112 << 16),
    124 | (2 << 8) | (112 << 16),
    125 | (2 << 8) | (111 << 16),
    127 | (2 << 8) | (111 << 16),
    129 | (2 << 8) | (110 << 16),
    131 | (2 << 8) | (110 << 16),
    132 | (2 << 8) | (109 << 16),
    134 | (2 << 8) | (109 << 16),
    136 | (2 << 8) | (108 << 16),
    138 | (2 << 8) | (107 << 16),
    139 | (3 << 8) | (107 << 16),
    141 | (3 << 8) | (106 << 16),
    143 | (3 << 8) | (105 << 16),
    145 | (3 << 8) | (104 << 16),
    147 | (3 << 8) | (103 << 16),
    149 | (3 << 8) | (102 << 16),
    150 | (3 << 8) | (101 << 16),
    152 | (3 << 8) | (100 << 16),
    154 | (3 << 8) | (99 << 16),
    156 | (3 << 8) | (98 << 16),
    158 | (3 << 8) | (97 << 16),
    160 | (3 << 8) | (96 << 16),
    162 | (3 << 8) | (94 << 16),
    164 | (3 << 8) | (93 << 16),
    166 | (3 << 8) | (92 << 16),
    168 | (3 << 8) | (90 << 16),
    170 | (3 << 8) | (89 << 16),
    172 | (3 << 8) | (87 << 16),
    174 | (3 << 8) | (86 << 16),
    176 | (3 << 8) | (84 << 16),
    178 | (3 << 8) | (82 << 16),
    180 | (3 << 8) | (80 << 16),
    182 | (3 << 8) | (79 << 16),
    184 | (3 << 8) | (77 << 16),
    186 | (3 << 8) | (75 << 16),
    188 | (3 << 8) | (73 << 16),
    191 | (3 << 8) | (71 << 16),
    193 | (3 << 8) | (69 << 16),
    195 | (3 << 8) | (66 << 16),
    197 | (3 << 8) | (64 << 16),
    199 | (3 << 8) | (62 << 16),
    201 | (3 << 8) | (59 << 16),
    204 | (3 << 8) | (57 << 16),
    206 | (3 << 8) | (54 << 16),
    208 | (3 << 8) | (52 << 16),
    210 | (3 << 8) | (49 << 16),
    212 | (3 << 8) | (46 << 16),
    215 | (3 << 8) | (44 << 16),
    217 | (3 << 8) | (41 << 16),
    219 | (3 << 8) | (38 << 16),
    222 | (3 << 8) | (35 << 16),
    224 | (3 << 8) | (32 << 16),
    226 | (3 << 8) | (29 << 16),
    228 | (3 << 8) | (26 << 16),
    231 | (3 << 8) | (22 << 16),
    233 | (3 << 8) | (19 << 16),
    236 | (3 << 8) | (16 << 16),
    238 | (3 << 8) | (12 << 16),
    240 | (3 << 8) | (8 << 16),
    243 | (3 << 8) | (5 << 16),
    245 | (5 << 8) | (3 << 16),
    248 | (8 << 8) | (3 << 16),
    250 | (12 << 8) | (3 << 16),
    251 | (17 << 8) | (4 << 16),
    251 | (23 << 8) | (7 << 16),
    251 | (29 << 8) | (9 << 16),
    251 | (35 << 8) | (11 << 16),
    251 | (41 << 8) | (14 << 16),
    252 | (47 << 8) | (16 << 16),
    252 | (52 << 8) | (19 << 16),
    252 | (58 << 8) | (21 << 16),
    252 | (64 << 8) | (24 << 16),
    252 | (69 << 8) | (26 << 16),
    252 | (75 << 8) | (29 << 16),
    252 | (80 << 8) | (31 << 16),
    252 | (85 << 8) | (34 << 16),
    252 | (91 << 8) | (36 << 16),
    252 | (96 << 8) | (39 << 16),
    252 | (101 << 8) | (41 << 16),
    252 | (106 << 8) | (44 << 16),
    252 | (111 << 8) | (46 << 16),
    252 | (116 << 8) | (49 << 16),
    252 | (121 << 8) | (52 << 16),
    252 | (126 << 8) | (54 << 16),
    253 | (130 << 8) | (57 << 16),
    253 | (135 << 8) | (59 << 16),
    253 | (140 << 8) | (62 << 16),
    253 | (144 << 8) | (65 << 16),
    253 | (148 << 8) | (67 << 16),
    253 | (153 << 8) | (70 << 16),
    253 | (157 << 8) | (73 << 16),
    253 | (161 << 8) | (76 << 16),
    253 | (165 << 8) | (78 << 16),
    253 | (169 << 8) | (81 << 16),
    253 | (173 << 8) | (84 << 16),
    253 | (177 << 8) | (87 << 16),
    253 | (181 << 8) | (89 << 16),
    253 | (185 << 8) | (92 << 16),
    253 | (188 << 8) | (95 << 16),
    253 | (192 << 8) | (98 << 16),
    253 | (195 << 8) | (101 << 16),
    253 | (199 << 8) | (103 << 16),
    253 | (202 << 8) | (106 << 16),
    253 | (205 << 8) | (109 << 16),
    254 | (209 << 8) | (112 << 16),
    254 | (212 << 8) | (115 << 16),
    254 | (215 << 8) | (118 << 16),
    254 | (218 << 8) | (121 << 16),
    254 | (220 << 8) | (124 << 16),
    254 | (223 << 8) | (127 << 16),
    254 | (226 << 8) | (129 << 16),
    254 | (228 << 8) | (132 << 16),
    254 | (231 << 8) | (135 << 16),
    254 | (233 << 8) | (138 << 16),
    254 | (235 << 8) | (141 << 16),
    254 | (238 << 8) | (144 << 16),
    254 | (240 << 8) | (147 << 16),
    254 | (242 << 8) | (150 << 16),
    254 | (244 << 8) | (153 << 16),
    254 | (246 << 8) | (156 << 16),
    254 | (247 << 8) | (150 << 16),
    254 | (249 << 8) | (163 << 16),
    254 | (250 << 8) | (166 << 16),
    254 | (252 << 8) | (169 << 16),
    254 | (253 << 8) | (172 << 16),
    254 | (254 << 8) | (175 << 16),
    253 | (254 << 8) | (178 << 16),
    252 | (254 << 8) | (181 << 16),
    251 | (254 << 8) | (184 << 16),
    250 | (254 << 8) | (188 << 16),
    249 | (254 << 8) | (191 << 16),
    248 | (254 << 8) | (194 << 16),
    248 | (254 << 8) | (197 << 16),
    247 | (254 << 8) | (200 << 16),
    247 | (254 << 8) | (204 << 16),
    247 | (254 << 8) | (207 << 16),
    247 | (254 << 8) | (210 << 16),
    247 | (254 << 8) | (213 << 16),
    247 | (254 << 8) | (217 << 16),
    247 | (254 << 8) | (220 << 16),
    247 | (254 << 8) | (223 << 16),
    247 | (254 << 8) | (227 << 16),
    248 | (254 << 8) | (230 << 16),
    248 | (254 << 8) | (233 << 16),
    249 | (254 << 8) | (237 << 16),
    249 | (254 << 8) | (237 << 16),
    249 | (254 << 8) | (237 << 16),
    248 | (254 << 8) | (237 << 16),
    248 | (254 << 8) | (237 << 16),
    248 | (254 << 8) | (237 << 16),
]

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import imgDataset1 from './dataset3.png'
import imgDataset2 from './dataset4.png'

const About = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h1>О проекте</h1>
                    <h5>
                        Информационно-аналитическое обеспечение методов
                        машинного обучения в области инфокоммуникаций
                    </h5>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <p>
                        Массивы данных включающие отсчеты сигналов и их
                        параметры
                    </p>

                    <h4>Имитатор</h4>
                    <p>
                        Отдельные датасеты сигналов имитатора для видов
                        модуляции ФМ/КАМ, ЧМ, OFDM с большим набором
                        сигнально-кодовых созвездий, с вариацией ОСШ, имитацией
                        расстройки несущей частоты и джиттера АЦП. Целевое
                        применение: (радиомониторинг), разработка новых и
                        оптимизации характеристик существующих схем приемных
                        устройств. Общее количество экземпляров сигналов около
                        350 тыс.
                    </p>

                    <h4>ВЧ-диапазон</h4>
                    <p>
                        В сигналах представлены ионосферные искажения, присущие
                        каналам связи ВЧ-диапазона. Экземпляры сигналов
                        соответствуют пакетным элементам сигнальной реализации.
                        Представлено около 20 тыс. экземпляров сигналов,
                        полученных из более чем 2 тыс. сигнальных реализаций.
                    </p>

                    <h4>Сигналы спутниковой связи</h4>
                    <p>
                        Данные получены на базе тракта приема антенной системы
                        L-диапазона «Центра Радиомониторинга» МТУСИ. Источником
                        сигналов являются каналы связи «ИСЗ – Абонент»
                        спутниковой системы «Инмарсат». 12 различных типов
                        каналов связи на скоростях 0.6, 1.2, 1.219, 2.438,
                        4.876, 6, 8.4, 33.6, 50, 151.2 кбод с видами модуляции
                        ФМ-2, ФМ-4, КАМ-16. Представлено около 10 тыс.
                        экземпляров сигналов,{' '}
                    </p>
                </Col>
                <Col lg="4">
                    <img alt="sample" width="400px" src={imgDataset1} />
                    <img alt="sample" width="400px" src={imgDataset2} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Демо</h1>
                    <Link to="/live">Спектрограмма в реальном времени</Link>
                </Col>
            </Row>
        </Container>
    )
}

export default About

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { SignalsAPI } from 'shared/api'
import Card from './Card'
import CategoryTab from './CategoryTab'

const Dashboard = (props) => {
    const [select, setSelect] = useState('signals')
    const [updating, setUpdating] = useState(true)
    const [filters, setFilters] = useState(null)

    useEffect(() => {
        SignalsAPI.getFilters({
            searchText: '',
            category: select,
            subcategory: '',
        }).then((data) => {
            setFilters(data)
            setUpdating(false)
        })
    }, [updating, select])

    const getSingleFilter = (name) => {
        return filters.find((p) => p._id === name)
    }

    return (
        <>
            <Row>
                <Col>
                    <CategoryTab
                        title="Датасеты"
                        total="7"
                        selected={select === 'datasets'}
                        onClick={() => setSelect('datasets')}
                    />
                </Col>
                <Col>
                    <CategoryTab
                        title="Сигналы"
                        total="10"
                        selected={select === 'signals'}
                        onClick={() => setSelect('signals')}
                    />
                </Col>
                <Col>
                    <CategoryTab
                        title="Статьи"
                        total="2"
                        selected={select === 'article'}
                        onClick={() => setSelect('article')}
                    />
                </Col>
            </Row>
            {updating ? (
                'Loading'
            ) : (
                <>
                    <Row>
                        <Col>
                            <Card filter={getSingleFilter('Modulation')} />
                            <Card filter={getSingleFilter('FrameLen')} />
                        </Col>
                        <Col>
                            <Card filter={getSingleFilter('SignalFormat')} />
                            <Card filter={getSingleFilter('SignalNumber')} />
                            <Card filter={getSingleFilter('FrameMode')} />
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default Dashboard

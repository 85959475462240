import React from 'react'
import { FormSelect } from 'react-bootstrap'

const SelectSignalDataFormat = ({ value, onChange }) => {
    const options = [
        { text: 'byte', value: 'byte' },
        { text: 'word (unsigned 16)', value: 'word' },
        { text: 'short (signed 16)', value: 'short' },
    ]
    const changeHandler = (e) => {
        onChange(e.target.value)
    }

    return (
        <FormSelect value={value} onChange={changeHandler}>
            {options.map((item) => (
                <option key={item.value} value={item.value}>
                    {item.text}
                </option>
            ))}
        </FormSelect>
    )
}

export default SelectSignalDataFormat

import {
    css,
    boundaries,
    toCoords,
    line,
    computeXRatio,
    computeYRatio,
} from './utils'

function noop() {}

export function sliderChart(root, data, opt) {
    var options = { ...opt }

    options.HEIGHT = 40
    options.DPI_HEIGHT = options.HEIGHT * 2
    options.WIDTH = options.DPI_WIDTH / 2
    options.MIN_WIDTH = options.WIDTH * 0.05

    //console.log('slider', options)

    const canvas = root.querySelector('canvas')
    const ctx = canvas.getContext('2d')
    let nextFn = noop
    canvas.width = options.DPI_WIDTH
    canvas.height = options.DPI_HEIGHT
    css(canvas, {
        width: options.WIDTH + 'px',
        height: options.HEIGHT + 'px',
    })

    const $left = root.querySelector('[data-el="left"]')
    const $window = root.querySelector('[data-el="window"]')
    const $right = root.querySelector('[data-el="right"]')

    function next() {
        nextFn(getPosition())
    }

    function mousedown(event) {
        const type = event.target.dataset.type
        const dimensions = {
            left: parseInt($window.style.left),
            right: parseInt($window.style.right),
            width: parseInt($window.style.width),
        }

        if (type === 'window') {
            const startX = event.pageX
            document.onmousemove = (e) => {
                const delta = startX - e.pageX

                if (delta === 0) {
                    return
                }

                const left = dimensions.left - delta
                const right = options.WIDTH - left - dimensions.width

                setPosition(left, right)
                next()
            }
        } else if (type === 'left' || type === 'right') {
            const startX = event.pageX
            document.onmousemove = (e) => {
                const delta = startX - e.pageX
                if (delta === 0) {
                    return
                }

                if (type === 'left') {
                    const left =
                        options.WIDTH -
                        (dimensions.width + delta) -
                        dimensions.right
                    const right =
                        options.WIDTH - (dimensions.width + delta) - left
                    setPosition(left, right)
                } else {
                    const right =
                        options.WIDTH -
                        (dimensions.width - delta) -
                        dimensions.left
                    setPosition(dimensions.left, right)
                }
            }
        }
    }

    function mouseup() {
        document.onmousemove = null
    }

    root.addEventListener('mousedown', mousedown)
    document.addEventListener('mouseup', mouseup)

    const defaultWidth = options.WIDTH * options.DEFAULT_WIDTH_PERCENT
    setPosition(0, options.WIDTH - defaultWidth)

    function setPosition(left, right) {
        const w = options.WIDTH - right - left + 1

        if (w < options.MIN_WIDTH) {
            css($window, { width: options.MIN_WIDTH + 'px' })
            return
        }

        if (left < 0) {
            css($window, { left: '0px' })
            css($left, { width: '0px' })
            return
        }

        if (right < 0) {
            css($window, { right: '0px' })
            css($right, { width: '0px' })
            return
        }

        css($window, {
            width: w + 'px',
            left: left + 'px',
            right: right + 'px',
        })

        css($right, { width: right + 'px' })
        css($left, { width: left + 'px' })

        next()
        if (options.onSliderChange !== undefined)
            options.onSliderChange(left / options.WIDTH, w / options.WIDTH)
    }

    function getPosition() {
        const left = parseInt($left.style.width)
        const right = options.WIDTH - parseInt($right.style.width)

        return [left, right]
    }

    function resize(DPI_WIDTH) {
        options.DPI_WIDTH = DPI_WIDTH
        options.HEIGHT = 40
        options.DPI_HEIGHT = options.HEIGHT * 2
        options.WIDTH = options.DPI_WIDTH / 2
        options.MIN_WIDTH = options.WIDTH * 0.05

        canvas.width = options.DPI_WIDTH
        canvas.height = options.DPI_HEIGHT
        css(canvas, {
            width: options.WIDTH + 'px',
            height: options.HEIGHT + 'px',
        })

        const defaultWidth = options.WIDTH * options.DEFAULT_WIDTH_PERCENT
        setPosition(0, options.WIDTH - defaultWidth)

        yRatio = computeYRatio(options.DPI_HEIGHT, yMax, yMin)
        xRatio = computeXRatio(options.DPI_WIDTH, data.columns[0].length)

        paint()
    }

    function paint() {
        yData
            .map(toCoords(xRatio, yRatio, options.DPI_HEIGHT, 0, yMin))
            .forEach((coords, idx) => {
                const color = data.colors[yData[idx][0]]
                line(ctx, coords, { color })
            })
    }

    var [yMin, yMax] = boundaries(data)
    var yRatio = computeYRatio(options.DPI_HEIGHT, yMax, yMin)
    var xRatio = computeXRatio(options.DPI_WIDTH, data.columns[0].length)

    const yData = data.columns.filter((col) => data.types[col[0]] === 'line')

    paint()

    return {
        subscribe(fn) {
            nextFn = fn
            fn(getPosition())
        },
        getPosition() {
            return getPosition()
        },
        setPosition(left, right) {
            return setPosition(left, right)
        },
        resize(width) {
            resize(width)
        },
    }
}

import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

import { getSubCategories } from 'entities/SignalDataScheme'

import { SignalsAPI } from 'shared/api'
import styles from './AggregateBar.module.css'

const AggregateBar = (props) => {
    const [updating, setUpdating] = useState(true)
    const [categories, setCategories] = useState(null)

    const RemoveDuplicate = (elements) => {
        let result = new Map()
        result.set('all', {
            _id: '[все]',
            path: '/' + props.category,
            count: 0,
        })
        const duplicates = new Set()
        let count = 0
        elements.forEach((element) => {
            count = count + element.count
            let s = element._id.toLowerCase()
            if (!duplicates.has(s)) {
                duplicates.add(s)
                result.set(s, element)
            } else {
                result.get(s)._id = element._id
            }
        })

        result.get('all').count = count
        return [...result.values()]
    }

    useEffect(() => {
        SignalsAPI.getCategory(props.category).then((data) => {
            setCategories(
                RemoveDuplicate([...data, ...getSubCategories(props.category)])
            )
            setUpdating(false)
        })
    }, [updating, props.category])

    return (
        <ListGroup>
            {' '}
            {updating
                ? 'Loading...'
                : categories.map((cat, index) => (
                      <ListGroup.Item
                          key={index}
                          action
                          className={styles.ListGroupItem}
                          style={{ color: 'green' }}
                      >
                          <NavLink
                              to={
                                  cat.path === undefined
                                      ? '/' + props.category + '/' + cat._id
                                      : cat.path
                              }
                              className={styles.NavLinkItem}
                              activeClassName={styles.active}
                          >
                              {cat._id}{' '}
                              <span style={{ float: 'right' }}>
                                  {cat.count > 0 ? cat.count : ''}
                              </span>
                          </NavLink>
                      </ListGroup.Item>
                  ))}
        </ListGroup>
    )
}

export default AggregateBar

import React, { useState, useEffect, useRef } from 'react'
import { spectrogram } from './spectrogram'

const SpectrogramView = ({ data, options }) => {
    const [bv, setBitView] = useState(null)
    const refView = useRef()

    useEffect(() => {
        if (data != undefined) {
            var b = spectrogram(refView.current, data, {})
            setBitView(b)
        }

        return () => {
            if (bv != null) bv.destroy()
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    if (bv != null) {
        bv.setOptions(options)
    }

    return (
        <div className="container-chart">
            <div className="card">
                <div ref={refView} className="bitview">
                    <canvas data-el="main"></canvas>
                </div>
            </div>
        </div>
    )
}

export default SpectrogramView

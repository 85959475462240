import React from 'react'
import { Spinner } from 'react-bootstrap'

export const Loader = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2rem',
        }}
    >
        <Spinner animation="grow" />
    </div>
)

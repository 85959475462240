import React, { useState, useEffect, useRef } from 'react'
import { bit } from './bit'

const BitView = ({ data, options }) => {
    const [bv, setBitView] = useState(null)
    const viewRef = useRef()

    useEffect(() => {
        console.log('ref', viewRef.current)
        if (data != undefined) {
            console.log('ref', viewRef.current)

            var b = bit(viewRef.current, data, {})
            setBitView(b)
        }

        return () => {
            if (bv != null) bv.destroy()
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    if (bv != null) bv.setOptions(options)

    return (
        <div className="container-chart">
            <div className="card">
                <div className="bitview" id="bv" ref={viewRef}>
                    <div data-el="tooltip" className="tg-chart-tooltip"></div>
                    <canvas data-el="main"></canvas>
                </div>
            </div>
        </div>
    )
}

export default BitView

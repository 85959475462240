import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTermins } from 'context/TerminContext'
import styles from './ArticleList.module.css'
import { translit } from 'shared/lib/translit/Translit'

const ArticleList = ({ onTerminClick }) => {
    const { termins, terminsReady } = useTermins()

    return (
        <>
            {terminsReady && (
                <div className={styles.TerminList}>
                    {termins.titles.map((termin, index) => (
                        <NavLink
                            key={index}
                            className={styles.TerminItem}
                            onClick={() => onTerminClick(termin)}
                            to={'/item/' + translit(termin)}
                            activeClassName="activeTermin"
                        >
                            {termin}
                        </NavLink>
                    ))}
                </div>
            )}
        </>
    )
}

export default ArticleList

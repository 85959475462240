import React from 'react'
import { useHistory } from 'react-router-dom'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { UploadAPI } from 'shared/api/upload.api'
import { SignalsAPI } from 'shared/api'

import { Container, Row, Col, Button } from 'react-bootstrap'
import SignalFileUpload from 'shared/ui/Inputs/SignalFileUpload'
import SelectSignalView from 'entities/SelectSignalView/ui/SelectSignalView'
import Stepper from 'shared/ui/Stepper/Stepper'
import PropertyGrid from 'shared/ui/PropertyGrid/PropertyGrid'

const FileUploadPage = () => {
    const history = useHistory()
    const signalAttachment = useLocalObservable(() => ({
        loaded: false,
        wizzardStep: 0,

        filename: undefined,
        destfilename: undefined,
        url: undefined,
        viewerName: 'SignalBitView',
        dataFormat: 'byte',
        get widgetName() {
            if (this.viewerName === 'SignalBitView') return 'widget-bitview-src'
            if (this.viewerName === 'SignalChart') return 'widget-pcm-src'
            if (this.viewerName === 'SignalSpectrogram')
                return 'widget-spectrogram-src'
            return 'widget-none'
        },
        setWizzardStep(step) {
            this.wizzardStep = step
        },
        setViewer(viewerName) {
            this.viewerName = viewerName
        },
        setDataFormat(format) {
            this.dataFormat = format
        },

        _id: undefined,
        props: [
            {
                name: 'category',
                value: 'signals',
            },
            {
                name: 'subcategory',
                value: 'samples',
            },
            {
                name: 'title',
                value: 'Заголовок',
            },
            {
                name: 'author',
                value: 'Авторы',
            },
            {
                name: 'summary',
                value: 'Краткое описание',
            },
            {
                name: 'description',
                value: 'Описание',
            },
        ],
        setProperty(id, name, value) {
            this.props[id].name = name
            this.props[id].value = value
        },
        getValue(name) {
            return this.props.find((item) => item.name === name).value
        },
        get signalProps() {
            var gridprops = [
                {
                    name: 'category',
                    value: this.getValue('category'),
                },
                {
                    name: 'subcategory',
                    value: this.getValue('subcategory'),
                },
                {
                    name: 'title',
                    value: this.getValue('title'),
                },
                {
                    name: 'author',
                    value: this.getValue('author'),
                },
                {
                    name: 'summary',
                    value: this.getValue('summary'),
                },
                {
                    name: 'description',
                    value: this.getValue('description'),
                },
                {
                    name: this.widgetName,
                    value: this.destfilename,
                    option: { property: true },
                },
                {
                    name: 'widget-dataformat',
                    value: this.dataFormat,
                    option: { property: true },
                },
            ]
            return gridprops
        },
        get signalDTO() {
            let res = {}

            res.category = this.getValue('category')
            res.subcategory = this.getValue('subcategory')
            res.title = this.getValue('title')
            res.author = this.getValue('author')
            res.summary = this.getValue('summary')
            res.description = this.getValue('description')

            res.props = this.signalProps
                .filter((item) => item.option && item.option.property === true)
                .map((prop) => {
                    return { name: prop.name, value: prop.value }
                })
            return res
        },
        loadFile(file) {
            UploadAPI.upload(file).then((data) =>
                runInAction(() => {
                    this.filename = data.name
                    this.destfilename = data.filename
                    this.url =
                        process.env.REACT_APP_DATASIG_STORAGE_URL +
                        data.filename
                    this.loaded = true
                    this.wizzardStep = 1
                })
            )
        },
        save() {
            if (this._id != undefined) {
                SignalsAPI.updateSignal(this._id, this.signalDTO).then(
                    (data) => {
                        runInAction(() => {
                            this.onSave(data)
                        })
                    }
                )
            } else {
                SignalsAPI.create(this.signalDTO).then((data) => {
                    runInAction(() => {
                        this.onSave(data)
                    })
                })
            }
        },
        onSave(signal) {
            history.push('/SignalItem/' + signal.hash)
        },
    }))

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Создание сигнала</h1>
                    <Stepper
                        value={signalAttachment.wizzardStep}
                        onChange={(value) => {
                            signalAttachment.setWizzardStep(value)
                        }}
                    />
                    {signalAttachment.wizzardStep == 0 && (
                        <SignalFileUpload
                            filename={signalAttachment.filename}
                            onSelectFile={signalAttachment.loadFile}
                        />
                    )}
                    {signalAttachment.wizzardStep == 1 &&
                        signalAttachment.loaded && (
                            <SelectSignalView attachment={signalAttachment} />
                        )}
                    {signalAttachment.wizzardStep == 2 && (
                        <>
                            <PropertyGrid
                                data={signalAttachment.signalProps}
                                onChange={signalAttachment.setProperty}
                            />
                        </>
                    )}
                    <Row className="justify-content-md-center">
                        <Col className="text-center">
                            {signalAttachment.wizzardStep > 0 && (
                                <>
                                    <Button
                                        variant="outline-success"
                                        onClick={() =>
                                            signalAttachment.setWizzardStep(
                                                signalAttachment.wizzardStep - 1
                                            )
                                        }
                                    >
                                        Назад
                                    </Button>{' '}
                                </>
                            )}
                            {signalAttachment.wizzardStep < 2 && (
                                <Button
                                    variant="success"
                                    onClick={() =>
                                        signalAttachment.setWizzardStep(
                                            signalAttachment.wizzardStep + 1
                                        )
                                    }
                                >
                                    Дальше
                                </Button>
                            )}
                            {signalAttachment.wizzardStep == 2 && (
                                <Button
                                    variant="success"
                                    onClick={signalAttachment.save}
                                >
                                    Создать сигнал
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <hr style={{ marginTop: '200px' }} />
                    <p>
                        url: {signalAttachment.url}
                        <br />
                        viewerName: {signalAttachment.viewerName}
                        <br />
                        dataFormat: {signalAttachment.dataFormat}
                    </p>{' '}
                </Col>
            </Row>
        </Container>
    )
}

export default observer(FileUploadPage)

import React, { useContext, useEffect, useState } from 'react'
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { useMessage } from 'shared/hooks/message.hook'
import { AuthContext } from 'context/AuthContext'
import { AuthAPI } from 'shared/api'

export const Auth = () => {
    const auth = useContext(AuthContext)
    const message = useMessage()
    const [form, setForm] = useState({
        email: '',
        password: '',
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        console.log('Update text fields')
    }, [])

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value })
    }

    // const registerHandler = async () => {
    //     try {
    //         const data = await request('/api/auth/register', 'POST', {
    //             ...form,
    //         })
    //         message(data.message)
    //     } catch (e) {}
    // }

    const loginHandler = async () => {
        try {
            const data = await AuthAPI.login({ ...form })
            auth.login(data.token, data.userId)
        } catch (e) {
            console.log(e)
            setError('Ошибка авторизации. Некоректный email или пароль')
        }
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="4">
                    <h1>Welcome</h1>
                    <hr />
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                onChange={changeHandler}
                                type="email"
                                placeholder="Enter email"
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                onChange={changeHandler}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                        {error && <Alert variant="warning">{error}</Alert>}
                        <Button
                            onClick={loginHandler}
                            variant="success"
                            disabled={loading}
                        >
                            Login
                        </Button>
                        &nbsp;
                        {/*<Button onClick={registerHandler} variant="secondary" disabled={loading}>
            Register
  </Button>*/}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

import React from 'react'
import { Table } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'
import styles from './Card.module.css'

const Card = ({ filter }) => {
    const colors = [
        '#001F3F',
        // '#0074D9',
        // '#7FDBFF',
        '#39CCCC',
        '#3D9970',
        '#2ECC40',
        '#01FF70',
        '#B10DC9',
        '#85144B',
        '#AAAAAA',
        '#DDDDDD',
        '#E38627',
        '#C13C37',
        '#6A2135',
    ]

    function randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min)
        return Math.floor(rand)
    }

    const rand = randomInteger(0, colors.length)

    const data =
        filter &&
        filter.values.map((f, index) => ({
            title: f.name,
            value: f.count,
            color: colors[(index + rand) % (colors.length - 1)],
        }))

    const total =
        data &&
        data.map((a) => a.value).reduce((sum, current) => sum + current, 0)
    console.log(total)

    return !filter ? (
        ''
    ) : (
        <div className={styles.card}>
            <div className={styles.cardLegend}>
                <Table size>
                    <thead>
                        <tr>
                            <th colSpan="4" style={{ borderTop: '0' }}>
                                {filter._id}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <span
                                        className={styles.circle}
                                        style={{ color: item.color }}
                                    ></span>
                                </td>
                                <td>{item.title}</td>
                                <td>{item.value}</td>
                                <td>
                                    {((item.value * 100) / total).toFixed(0)}%
                                </td>
                            </tr>
                        ))}

                        {/* <tr>
                            <th>Всего: </th>
                            <th>{total}</th>
                            <th></th>
                            <th></th>
                        </tr> */}
                    </tbody>
                </Table>
            </div>
            <div className={styles.cardChart}>
                <PieChart
                    lineWidth="40"
                    style={{ verticalAlign: 'top' }}
                    data={data}
                />
                <div className={styles.chartInnerLabel}>
                    ВСЕГО
                    <div className={styles.chartInnerValue}>{total}</div>
                </div>
            </div>
        </div>
    )
}

export default Card

import React, { useEffect, useState } from 'react'
import Splitter, { SplitDirection } from '@devbookhq/splitter'
import FileList from 'entities/TaskFileList/ui/FileList'

import { Loader } from 'shared/ui/Loader/Loader'
import { SignalsAPI } from 'shared/api'
import FilePreview from 'entities/TaskFileList/ui/FilePreview'
import { Row, Col } from 'react-bootstrap'

const FilePanel = (props) => {

    const [updating, setUpdating] = useState(true)
    const [task, setTask] = useState(null)
    const [previewText, setPreviewText] = useState("click to select ...")
    const [selectedFile, setSelectedFile] = useState(null)

    useEffect(() => {
        SignalsAPI.getTaksByHash(props.id).then((tasks) => {
            setTask(tasks == null ? null: tasks[0])
            setUpdating(false)
        })
    }, [updating, props.id])

    useEffect(() => {
        const load = () => {
            SignalsAPI.getFilePreview(selectedFile).then((text) => {
                setPreviewText(text)
            })
        }

        if (selectedFile != null) load()
    }, [selectedFile])

    const selectHandler = (file) => {
        console.log('select', file)
        setSelectedFile(file)
    }

    if (task == null) return null
    // <Loader />
    return (
            <>
            

<p>Файл сигнала был обработан c помощью сценария</p>
            <h5>Лог обработки</h5>
            <pre>{task.taskResult}</pre>
        <hr/>
        <h5>Результаты</h5>
        <p>В результате автоматизированной обработки были получены следующие материалы:</p>
        <Row>
            <Col xs lg="4">
            
                <FileList files={task.taskFiles} onSelect={ selectHandler}/>
            </Col>
            <Col xs="11" lg="8">
                <FilePreview text={previewText}/>
            </Col>
        </Row>

            {/* <Splitter direction={SplitDirection.Horizontal} initialSizes={[20, 80]}>
    </Splitter> */}
            
            
            </>
    )
}

export default FilePanel
import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ButtonWithPlusIcon = (props) => {
    return (
        <Button onClick={props.onClick} variant="success">
            <FontAwesomeIcon icon={faPlus} className="" />
        </Button>
    )
}

export default ButtonWithPlusIcon

import React from 'react'
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'

const SinglePropEditor = ({ _id, name, value, onChange, onRemove }) => {
    return (
        <Form.Group as={Row} controlId="value">
            <Col sm="1"></Col>
            <Form.Label column sm="2">
                {name}
            </Form.Label>

            <Col sm="9">
                <InputGroup>
                    <Form.Control
                        type="text"
                        defaultValue={value}
                        onChange={(event) =>
                            onChange({ _id, name, value: event.target.value })
                        }
                    />
                    <InputGroup.Append>
                        <Button
                            variant="outline-secondary"
                            onClick={() => onRemove(_id)}
                        >
                            Удалить
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        </Form.Group>
    )
}

export default SinglePropEditor

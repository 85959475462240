import React from 'react'
import styles from './Stepper.module.scss'

const Stepper = ({ value, onChange }) => {
    const steps = [
        { title: 'Загрузите сигнал' },
        { title: 'Настройте отображение' },
        { title: 'Заполните свойства' },
    ]

    const selectValueHandler = (e) => {
        onChange(e.target.value)
    }

    return (
        <>
            <div className={styles.steps}>
                <progress
                    className={styles.stepProgress}
                    value={(value * 100) / (steps.length - 1)}
                    max="100"
                ></progress>
                {steps.map((item, index) => (
                    <div className={styles.stepItem} key={index}>
                        <button
                            className={`${styles.stepButton} ${
                                value == index ? 'collapsed' : ''
                            }`}
                            value={index}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={value >= index}
                            aria-controls={`#collapse${index}`}
                            onClick={selectValueHandler}
                        >
                            {index + 1}
                        </button>
                        <div className={styles.stepTitle}>{item.title}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Stepper

import React, { useContext, useState, useEffect, createContext } from 'react'
import { SignalsAPI } from 'shared/api'
import { SignalDataScheme } from 'entities/SignalDataScheme'

const TerminContext = createContext()

export function TerminContextProvider({ children }) {
    const [terminsReady, setTerminsReady] = useState(false)
    const [termins, setTermins] = useState()
    useEffect(() => {
        Promise.all([
            SignalsAPI.getTermins(),
            SignalsAPI.getArticleTitles(),
        ]).then(([data1, data2]) => {
            console.log(data1, data2)
            setTermins(
                new SignalDataScheme({
                    termins: data1.termins,
                    titles: data2.titles,
                })
            )
            setTerminsReady(true)
        })
    }, [])
    return (
        <TerminContext.Provider value={{ termins, terminsReady }}>
            {children}
        </TerminContext.Provider>
    )
}

// Create a hook to use the TerminContext, this is a Kent C. Dodds pattern
export function useTermins() {
    const context = useContext(TerminContext)
    if (context === undefined) {
        throw new Error('context/ must be used within a Provider')
    }
    return context
}
